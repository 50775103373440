import { useSelector } from "react-redux";
import { getSettlementRecord, postSettlementRecord,  reportShareholderList } from "../../../config/networkConfig";
import { useEffect, useState, useRef } from "react";
import { alertData, sharedErrorAlert, sharedSuccessAlert, sharedFailAlert } from "../../../common/constants/sharedMsgBox";
import { Checkbox, Table, Button, Modal, Text, Switch, PageHeader, Select} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import moment from "moment";

export default function ReportSettlement({shareholder, setShareholder, shareholderName}){
    let {userId} = useSelector((state) => state.login)
    // console.log("Shareholder ID : ",shareholder)
    const { Option } = Select;
    const { role } = useSelector((state) => state.login);
    const [settlementData, setSettlementData] = useState([]);
    const [visible, setVisible] = useState(false)
    const [tableLoading, setTableLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [expand, setExpand] = useState(true)
    const [mode, setMode] = useState("")
    const [confirmModal, setConfirmModal] = useState(false)
    const [shareholderSelection, setShareholderSelection] = useState(null);
    const [shareholderOptions, setShareholderOptions] = useState([]);
    const reportShareholderRef = useRef(null);
    const [isAdminCS, setIsAdminCS] = useState(false);

    //Status Filter
    const [status, setStatus] = useState("All");

    useEffect(() => {
        shareholderList();
    }, [status]);

    useEffect(() => {
        if (role === "Admin_CS") {
        setIsAdminCS(true);
        } else {
        setIsAdminCS(false);
        }
    }, [role]);

    useEffect(()=> {
        setShareholderSelection(shareholderSelection);
    },[shareholderSelection]);
  
    // useEffect(() => {
    //     // if (shareholder){
    //     //     userId = shareholder
    //     // }
    //     // console.log("UserID in useEffect : ",userId)
    //     getSettlement()
    // }, []);

    useEffect(() => {
        if (role !== 'Admin_CS') {
            setTableLoading(true);
            getSettlement();
        }
    }, [role]);

    let selectedSettlement = settlementData.find((e) => e.Select == true)
    let selectedData = settlementData.filter((e) => e.Select == true)
    let ascSortedData = selectedData.sort((a, b) => {return (new Date(a.AccountMonth) - new Date(b.AccountMonth))})

    const calculateSettlement = () => {
        let settlementAmount = 0
        let settlementArr = selectedData.map((e) => {return e.ProfitSharing})
        settlementAmount = settlementArr.reduce((a,b) => 
            a + b , 0
        )
        return settlementAmount
    }
    
    const resolveSettlementAmount = (sortedSettlement) => {
        let totalAmount = calculateSettlement()
        let negativeArr = sortedSettlement.map((e) => {if (e.ProfitSharing < 0) {return e.ProfitSharing} else {return 0}})
        let totalNegative = negativeArr.reduce((a, b) => a + b , 0)
        let cumulativeAmount = 0
        for (let i = 0; i < sortedSettlement.length; i ++){
            if (mode == "pay"){
                let payout = sortedSettlement[i].ProfitSharing
                if (payout< 0){
                    sortedSettlement[i] = {...sortedSettlement[i], PaidAmount : 0}
                } else {
                    //In case where cumulative amount is not negative, payout = profitsharing
                    if (totalNegative > 0){
                        sortedSettlement[i] = {...sortedSettlement[i], PaidAmount : payout}
                    }
                    //In case where cumulative amount is negative, payout = profit sharing - negative amount
                    else {
                        if (totalNegative + payout < 0){
                            sortedSettlement[i] = {...sortedSettlement[i], PaidAmount : 0}
                            totalNegative += payout
                        } else {
                            sortedSettlement[i] = {...sortedSettlement[i], PaidAmount : payout + totalNegative}
                            totalNegative += payout
                        }
                    }
                }
            } else {
                sortedSettlement[i] = {...sortedSettlement[i], PaidAmount : 0}
            }
        }
        return sortedSettlement
    }

    const shareholderList = async () => {
        try{
            let requestData = {
                role : role,
                userId : userId
            }

            const response = await reportShareholderList(requestData);
            let result = await response.json();
            
            if (result.status === 'ok') {
                setShareholderOptions(result.msg != 'No record.' ? result.data : []);
            }
            else{
                alertData.title = "Failed";
                alertData.msg = result.msg;
                sharedFailAlert(alertData);
            }  
        }
        catch (error) {
            alertData.title = "Error";
            alertData.msg = "Failed to fetch shareholder list";
            sharedFailAlert(alertData);
        }
    }

    const searchBar = () => {
    if (role === 'Admin_CS' && !shareholderSelection) {
        reportShareholderRef.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select a shareholder !";
        sharedErrorAlert(alertData);
        return;
    }
    setTableLoading(true);
    getSettlement();
    }

    const resetBtn = () => {
        window.location.reload();
    };

    const getSettlement = async () => {
        setTableLoading(true)
        if(role === 'Admin_CS'){
            if (!shareholderSelection) {
                setTableLoading(false);
                return;
            }
            userId = shareholderSelection;
        }else if (shareholder){
            userId = shareholder;
        }
        // if (shareholder){
        //     userId = shareholder
        // }
        let result= await getSettlementRecord({userId: userId, role: role})
        result = await result.json()
        // console.log("RAW : ", result)
        let settlementData = result.data[1]
        let ptPercent = result.data[0]
        if (result.status == "err"){
        //     alertData.title = "Error"
        //     alertData.msg = result.msg
        //     sharedErrorAlert(alertData)
            return
        }
        // console.log("Received data : " ,settlementData)
        if (result.data.length == 2){
            let count = 0
            let tableInput = settlementData.map((e) => {
                count++
                return {
                    key : count,
                    AccountMonth : e.AccountMonth,
                    ProfitSharing : e.ProfitSharing,
                    Status : e.Status == 0 ? "Pending" : e.Status == 1 ? "Processed" : e.Status ==  2 ? "Approved" : e.Status == 3 ? "Paid" : e.Status == 5 ? "Suspend" : e.Status == 6 ? "Void / Waive" : "Cancel",
                    Select : false,
                    UserID : userId,
                    ProcessTime : e.SysPaidTime ? convertTimestamp(e.SysPaidTime) : e.SysProcessTime ? convertTimestamp(e.SysProcessTime) :  "",
                    PaidAmount : e.PaidAmount ? e.PaidAmount : 0,
                    GGR : e.GrossGamingRevenue,
                    Royalties : e.Royalty,
                    Promotion : e.PromotionCost,
                    Affiliate : e.AffiliateCost,
                    CRM : e.CrmCost,
                    Operation : e.OperationCost,
                    Custom : e.CustomFee,
                    PtPercent : ptPercent.PositionTaking
                }
            })

            let sortedDate = tableInput.sort((a,b) => {
                return (new Date(b.AccountMonth) - new Date(a.AccountMonth))
            })
            
            // console.log("Table input value : ",tableInput)
            setTableLoading(false)
            setSettlementData(sortedDate)
        } else if (result.data.length == 0 ) {
            alertData.title = "Error";
            alertData.msg = "No data !";
            sharedErrorAlert(alertData);
            setTableLoading(false);
            return;
          }else {
            alertData.title = "Error";
            alertData.msg = result.msg;
            sharedErrorAlert(alertData);
            setTableLoading(false)
            return 
        }
    }   
    
    const convertTimestamp = (timestamp) => {
        if (timestamp == 0){
            return ""
        }
        let date = moment.unix(timestamp).format("YYYY-MM-DD HH:mm:ss")
        return date
    }

    const handleCheckbox = async (e) => {
        const updatedData = settlementData.map((x) => 
            x.key === e.key ? {...x, Select : !x.Select} : x
        )
        setSettlementData(updatedData)
    }

    const openModal = () => {
        setVisible(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const handleCancelConfirm = () => {
        setConfirmModal(false)
    }

    const handleSubmit = async (mode) => {
        setMode(mode)
        setConfirmModal(true)
    }

    const handleConfirm = async () => {
        await processSettlement()
        setConfirmModal(false)
        setMode("")
    }

    const processSettlement = async () => {
        setButtonLoading(true)
        let resolvedData = {mode : mode , data : resolveSettlementAmount(ascSortedData, mode)}
        console.log("INPUT DATA : ", resolvedData)
        let response = await postSettlementRecord(resolvedData)
        let result = await response.json();
        console.log("Post result : ", result)
        if (result.status == "err"){
                alertData.title = "Error"
                alertData.msg = result.msg
                sharedErrorAlert(alertData)
                setButtonLoading(false)
                return
        } else {
            alertData.title = "Success"
            alertData.msg = result.msg
            sharedSuccessAlert(alertData)
        }
        getSettlement()
        setButtonLoading(false)
        setVisible(false)
    }

    const handleSwitch = () => {
        setExpand(!expand)
    }

    const mainColumn1 = [
        {
            title : "Month",
            dataIndex : "AccountMonth",
            width:100
        },
        {
            title : "Profit Sharing",
            dataIndex: "ProfitSharing",
            style: [{ "word-wrap": "break-word" }],
            width:150
        },
    ]

    const mainColumn2 = [
        {
            title: "Processed Time",
            dataIndex: "ProcessTime",
            width:180
        },
        {
            title: "Paid Amount",
            dataIndex: "PaidAmount",
            width:130
        },
        {
            title: "Status",
            dataIndex: "Status",
            width:120
        }
    ]

    const columnSelect = {
        title: " ",
        dataIndex: "Select",
        width:"50px",
        render: (_ , e) => 
            (e.Status == "Pending" ? <Checkbox checked = {e.Select} onChange={() => handleCheckbox(e)}/> : "")
    }

    const columnExpanded = [
        {
            title: "GGR",
            dataIndex: "GGR",
            width: expand == true ? 150 : 0,
        },
        {title: "Royalties",
            dataIndex: "Royalties",
            width: expand == true? 150 : 0
        },
        {title: "Promotion",
            dataIndex: "Promotion",
            width: expand == true? 150 : 0
        },
        {title: "Affiliate",
            dataIndex: "Affiliate",
            width: expand == true? 150 : 0
        },
        {title: "CRM",
            dataIndex: "CRM",
            width: expand == true? 150 : 0
        },
        {title: "Operation",
            dataIndex: "Operation",
            width: expand == true? 150 : 0
        },
        {title: "Custom",
            dataIndex: "Custom",
            width: expand == true? 150 : 0
        },
        {title: "PtPercent",
            dataIndex: "PtPercent",
            width: expand == true? 150 : 0
        }
    ]

    let tableColumn = expand ? [...mainColumn1, ...columnExpanded, ...mainColumn2] : [...mainColumn1, ...mainColumn2]
    tableColumn = shareholder ? [columnSelect, ...tableColumn] : tableColumn


    const modalTableColumn = [{
        title : "Month",
        dataIndex : "AccountMonth"
    },
    {
        title : "Profit Sharing",
        dataIndex: "ProfitSharing"
    }]

    const styles = {
        pageHeaderContainer: {
            display: "inline",
        },
        resetBtn: {
            marginLeft: 10,
        },
    };

    return  (
        <>
        {shareholder ? "" :
        <PageHeader
            ghost={false}
            title="Settlement Report"
            style={{marginBottom: 10}}
        >
        <div style={{textAlign:"left", marginBottom:10}}>
            {/* Show More:  <Switch onChange={handleSwitch} defaultChecked/> */}
            {isAdminCS && (
              <div style={styles.pageHeaderContainer}>
                  <Select
                    showSearch
                    value={shareholderSelection}
                    optionFilterProp="children"
                    ref={reportShareholderRef}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder="Please select a shareholder"
                    style={{width: '230px'}}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(value) => {
                      setShareholderSelection(value);
                      // for (let i = 0; i < uplineOptions.length; i++) {
                      //   if (uplineOptions[i].username === value) {
                      //     // console.log("Selected upline:", uplineOptions[i]);
                      //   }
                      // }
                    }}
                  >
                    {shareholderOptions.map((option) => {
                      return (
                        <Option key={option.key} value={option.shareholder}>
                          {option.username}
                        </Option>
                      );
                    })}
                  </Select>
                  <Button
                    type={"primary"}
                    icon={<SearchOutlined
                    onClick={searchBar}
                    />}
                  />
                  <Button style={{...styles.resetBtn,...styles.refreshBtn}} onClick={resetBtn}>
                    Reset
                  </Button>
                </div>
            )}
        </div>
        </PageHeader>}
        {shareholder ?
        <div style ={{justifyContent:"space-between",display:"flex", marginBottom : 10, marginLeft: 20}}>
            <div>
            <Button type="secondary" onClick={() => setShareholder("")}>Back</Button>
            </div>
            <div>
            <Button type="primary"  onClick={() => openModal()} disabled={selectedSettlement ? false : true}>Process Settlement</Button>
            </div>
        </div> : ""}
        <Modal 
        title= "Process Settlement Preview"
        visible={visible}
        closable={false}
        centered="true"
        footer={[
            <Button loading={buttonLoading} disabled = {selectedSettlement && calculateSettlement() >= 0 ? false : true} key="submit" type="primary" onClick={() => handleSubmit("pay")}>Pay</Button>,
            <Button loading={buttonLoading} key="waive" type="danger" onClick={() => handleSubmit("waive")}>Waive</Button>,
            <Button loading={buttonLoading} key="cancel" type="secondary" onClick={handleCancel}>Cancel</Button>
        ]}
        >
            <Table columns={modalTableColumn} dataSource={ascSortedData} />
            
            
            <p>Total amount : {calculateSettlement()}</p>
            
        </Modal>

        <Modal 
        title= "Confirmation"
        visible={confirmModal}
        closable={false}
        centered="true"
        footer={[
            <Button loading={buttonLoading} key="waive" type="primary" onClick={handleConfirm}>OK</Button>,
            <Button loading={buttonLoading} key="cancel" type="secondary" onClick={handleCancelConfirm}>Cancel</Button>
        ]}
        >            
            
            <p>{
                mode == "pay" ? `Please confirm you have transferred ${calculateSettlement()} to ${shareholderName}.` 
                :mode == "waive" ? `This action will waive ${calculateSettlement()}, are you sure?`
                :"" }</p>
            
        </Modal>

            <Table 
                loading={tableLoading} 
                columns={tableColumn} 
                dataSource={settlementData} 
                scroll={{ x: 100, y: null }}
                style={{marginLeft: 20 }} 
                pagination={{
                    defaultPageSize: 100,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20"]
                    // style: styles.paginationStyle,
                  }}
            />

        </>);

}
// You need to select the brand u want to start or deploy. Eg. --> BRAND=[BRAND] 

// ===== To npm start =====
// 1) BRAND=STAGING1 npm start

// ===== The step of deployment =====
// 1) BRAND=STAGING1 npm run-script build
// 2) scp -P 20836  -i ../terabox.pem -r  ./build/*  ec2-user@ec2-52-220-70-148.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo


const selectedBrand = process.env.REACT_APP_BRAND; // STAGING1 <-- the brand u selected
const brandDomains = { 
  // TEMPLATE
  'WHITELABELTEMPLATE'  : 'boapi.devh5apidemo.xyz',   // ec2-user@ec2-54-254-231-113.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo

  // STAGING
  'STAGING777'          : '777bo_api.ogstaging5.xyz', // ec2-user@ec2-3-0-240-30.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'STAGING1'            : 'boapi.ogstaging1.xyz',     // ec2-user@ec2-52-220-70-148.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'STAGING2'            : 'boapi.devstaging2.xyz',    // ec2-user@ec2-13-214-48-48.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'STAGING3'            : 'boapi.devstaging3.xyz',    // ec2-user@ec2-3-1-44-123.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'STAGING4'            : 'boapi.devstaging4.xyz',    // ec2-user@ec2-3-0-240-30.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'STAGING5'            : 'boapi.devstaging5.xyz',    // ec2-user@ec2-18-143-235-26.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo

  // PRODUCTION
  'TKB'                 : 'www.2f826cab.com',         // ec2-user@ec2-18-138-65-36.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB555'              : 'boapi.xceb8p6qqe.com',// ec2-user@ec2-54-254-30-114.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB777'              : 'www.ogh5api1.site',        // ec2-user@ec2-18-138-5-6.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB365'              : 'boapi.n8nj3xwmdt.com', // ec2-user@ec2-54-255-16-178.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'THB889'              : 'boapi.devh5apild0dw1.com', // ec2-user@ec2-52-77-179-191.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB99'               : 'boapi.99beth5api.store',   // ec2-user@ec2-18-136-233-64.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'SEXYWIN'             : 'boapi.devh5apiycf6fp.com', // ec2-user@ec2-52-77-171-172.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'SIAM96'              : 'boapi.siam96.store',       // ec2-user@ec2-54-179-93-162.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'CHANGBET789'         : 'boapi.devh5apihwp3o6.com', // ec2-user@ec2-18-138-69-65.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBNEKO'             : 'boapi.devh5api3.shop',     // ec2-user@ec2-18-140-105-173.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'BONANZA99'           : 'boapi.devh5api8.shop',     // ec2-user@ec2-13-228-136-108.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB24'               : 'boapi.v5ox847j15.com',      // ec2-user@ec2-18-138-223-117.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBWIN'              : 'boapi.devh5api7.shop',     // ec2-user@ec2-18-136-46-139.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBKING'             : 'boapi.devh5api9.shop',     // ec2-user@ec2-18-142-247-19.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB91'               : 'boapi.devh5api10.shop',    // ec2-user@ec2-3-1-246-254.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBDEMO'             : 'boapi.devh5api11.shop',    // ec2-user@ec2-52-74-223-37.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'BKK369'              : 'boapi.devh5api6.shop',     // ec2-user@ec2-18-138-12-87.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PGBET888'            : 'boapi.devh5api13.shop',    // ec2-user@ec2-13-228-230-117.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PGWIN828'            : 'boapi.devh5api12.shop',    // ec2-user@ec2-18-140-40-80.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'AMB24'               : 'boapi.devh5api6fqemz.com', // ec2-user@ec2-18-143-221-176.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'THA555'              : 'boapi.devh5api15.shop',    // ec2-user@ec2-52-77-148-99.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'SWD555'              : 'boapi.devh5api16.shop',    // ec2-user@ec2-175-41-143-115.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'AMBK8'               : 'boapi.devh5api05lw8d.com', // ec2-user@ec2-3-0-45-22.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'SIAMBET88'           : 'boapi.devh5api18.site',    // ec2-user@ec2-13-228-76-132.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'RWY88'               : 'boapi.devh5apih4h6va.com', // ec2-user@ec2-18-139-94-71.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TARA555'             : 'boapi.devh5api20.xyz',     // ec2-user@ec2-18-138-179-183.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB889'              : 'boapi.devh5api21.xyz',     // ec2-user@ec2-54-151-222-140.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PGCAT555'            : 'boapi.devh5api22.xyz',     // ec2-user@ec2-54-179-96-244.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'LINEBET88'           : 'boapi.devh5api23.xyz',     // ec2-user@ec2-18-140-4-26.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBBET'              : 'boapi.devh5apimoym18.com', // ec2-user@ec2-13-215-247-129.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB338'              : 'boapi.devh5apibnu3f3.com', // ec2-user@ec2-13-215-188-17.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB918'              : 'boapi.devh5apiex4oew.com', // ec2-user@ec2-52-74-2-33.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'BIG888'              : 'boapi.devh5api27.xyz',     // ec2-user@ec2-13-215-125-211.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB22'               : 'boapi.devh5apiz2i9a4.com', // ec2-user@ec2-52-76-180-73.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'BKM88'               : 'boapi.devh5api29.xyz',     // ec2-user@ec2-13-215-144-161.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB4'                : 'boapi.devh5api657d0i.com', // ec2-user@ec2-13-228-207-145.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB9'                : 'boapi.devh5apixv83fk.com', // ec2-user@ec2-18-138-136-29.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB96'               : 'boapi.devh5apih1hodo.com', // ec2-user@ec2-13-213-111-115.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB88'               : 'boapi.devh5api8hneen.com', // ec2-user@ec2-52-77-40-114.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB33'               : 'boapi.devh5apinew5ze.com', // ec2-user@ec2-54-251-71-141.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBPG'               : 'boapi.devh5apihtv1rz.com', // ec2-user@ec2-18-138-167-173.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBSEXY'             : 'boapi.devh5apiac3n5b.com', // ec2-user@ec2-13-213-114-50.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBPUSSY'            : 'boapi.devh5apipjw3lp.com', // ec2-user@ec2-18-138-48-224.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBFUN'              : 'boapi.devh5apiv0jqqe.com', // ec2-user@ec2-46-51-218-216.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBRICH'             : 'boapi.y6mnnj8vi5ugw5.com', // ec2-user@ec2-52-74-187-205.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'THLUCK99'            : 'boapi.devh5api2mth6g.com', // ec2-user@ec2-54-169-103-54.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'GRAND86'             : 'boapi.devh5api41.com',     // ec2-user@ec2-52-74-227-147.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBQUEEN'            : 'boapi.devh5api9dvza9.com', // ec2-user@ec2-18-140-209-112.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PGCASH88'            : 'boapi.devh5api1o8y55.com', // ec2-user@ec2-13-228-102-17.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PGLIVE888'           : 'boapi.devh5apimo9a21.com', // ec2-user@ec2-54-251-135-7.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'FAFA828'             : 'boapi.devh5apir9xike.com', // ec2-user@ec2-18-139-82-86.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PGMAX888'            : 'boapi.devh5apiujx3t4.com', // ec2-user@ec2-54-255-11-7.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PGS555'              : 'boapi.devh5apiwnt02c.com', // ec2-user@ec2-18-142-190-152.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'VIU99'               : 'boapi.devh5apii2npz5.com', // ec2-user@ec2-18-139-126-198.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'IBIZA99'             : 'boapi.devh5apiq0uvg5.com', // ec2-user@ec2-18-142-2-38.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PEH888'              : 'boapi.devh5api8urs5d.com', // ec2-user@ec2-47-128-191-135.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'DMK55'               : 'boapi.devh5apis7m8vz.com', // ec2-user@ec2-13-213-243-140.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'NEKO555'             : 'boapi.devh5apiqc3ul5.com', // ec2-user@ec2-54-255-77-140.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PGJOY777'            : 'boapi.devh5api81w4rt.com', // ec2-user@ec2-18-141-62-43.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'BAHT333'             : 'boapi.devh5apimezv3m.com', // ec2-user@ec2-18-142-223-8.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB138'              : 'boapi.devh5apiyd47yg.com', // ec2-user@ec2-52-220-63-157.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'KHA99'               : 'boapi.devh5apice5ics.com', // ec2-user@ec2-18-143-105-20.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'MCD999'              : 'boapi.devh5apiffef0e.com', // ec2-user@ec2-18-141-70-173.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'BAHT555'             : 'boapi.devh5api2igps7.com', // ec2-user@ec2-54-251-139-188.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PGSPIN55'            : 'boapi.devh5apiu303r4.com', // ec2-user@ec2-13-213-220-166.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PGSLOT33'            : 'boapi.devh5api477yiz.com', // ec2-user@ec2-13-228-231-0.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'PGNEKO88'            : 'boapi.devh5apio28vmw.com', // ec2-user@ec2-18-143-37-72.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'ROVSLOT'             : 'boapi.devh5apib11eol.com', // ec2-user@ec2-3-1-96-243.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'FAH999'              : 'boapi.devh5apizo9ird.com', // ec2-user@ec2-13-215-111-17.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'XO98'                : 'boapi.devh5apitfuu3y.com', // ec2-user@ec2-13-228-85-10.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB108'              : 'boapi.devh5apisp5sat.com', // ec2-user@ec2-54-151-224-139.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'AMB321'              : 'boapi.devh5apim4fuko.com', // ec2-user@ec2-13-228-153-242.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'AMB33'               : 'boapi.devh5apiyiyc7.com' , // ec2-user@ec2-13-213-104-13.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBCLUB'             : 'boapi.devh5api8yv6fn.com', // ec2-user@ec2-54-254-193-194.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB123'              : 'boapi.devh5api9gbrtg.com', // ec2-user@ec2-52-77-127-49.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB321'              : 'boapi.devh5apicsfhb0.com', // ec2-user@ec2-46-51-222-8.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB666'              : 'boapi.devh5apiqmcwht.com', // ec2-user@ec2-52-77-29-221.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB929'              : 'boapi.devh5apitbcguo.com', // ec2-user@ec2-18-142-68-232.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'LEO777'              : 'boapi.devh5apiurdujc.com', // ec2-user@ec2-13-213-153-138.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'AGV55'               : 'boapi.devh5api265vxm.com', // ec2-user@ec2-18-143-229-179.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB247'              : 'boapi.devh5api8jqs8x.com', // ec2-user@ec2-52-74-136-8.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'DAW555'              : 'boapi.devh5apitnnztf.com', // ec2-user@ec2-175-41-160-83.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKBTOP'              : 'boapi.devh5api84wixj.com', // ec2-user@ec2-47-128-177-185.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB303'              : 'boapi.devh5apihhunqe.com', // ec2-user@ec2-18-136-246-143.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'TKB808'              : 'boapi.devh5apicod24r.com', // ec2-user@ec2-13-228-120-111.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'BKM123'              : 'boapi.devh5apiuwm898.com', // ec2-user@ec2-52-220-254-170.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'BKM222'              : 'boapi.devh5apijkqimq.com', // ec2-user@ec2-54-151-206-67.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'BKM777'              : 'boapi.devh5api9di7gw.com', // ec2-user@ec2-13-213-108-76.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'JKR88'               : 'boapi.matyqzbz3c.com', // ec2-user@ec2-175-41-160-83.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'UVO88'               : 'boapi.85gkt9mf90.com', // ec2-user@ec2-3-0-110-44.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'JOIN999'             : 'boapi.2t2m3sqcuj.com', // ec2-user@ec2-54-255-133-22.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'AZTEC888'            : "boapi.84vgmnn29n.com", // ec2-user@ec2-52-220-168-195.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  'SIAM188'             : "boapi.rbmju2ek4p.com", // ec2-user@ec2-3-1-106-175.ap-southeast-1.compute.amazonaws.com:/var/www/html/appbox_bo
  // Added via aws script
    
    "HAHA55": "boapi.aozj3mknfh.com",

};

const getDomainForBrand = (brand) => {
  if (!brandDomains[brand]) {
    console.error(`Error: Brand "${brand}" is not recognized. Please check your configuration.`);
    // Exit the process with a non-zero exit code
    process.exit(1);
  }

  return brandDomains[brand];
};

export const DOMAIN = getDomainForBrand(selectedBrand);
export const BRAND = process.env.REACT_APP_BRAND;
export const VERSION = "1.2.6.6.3G";

console.log(`Domain for ${selectedBrand}:`, DOMAIN); // Output: Domain for staging777: 777bo_api.ogstaging5.xyz
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  SearchOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { 
  reportPlayerV2,
  reportPlayerV3,
  reportPlayerShareholder,
  reportPlayerShareholderV2,
  reportPlayerShareholderV3,
  reportShareholderList
 } from "../../../config/networkConfig";
import { Table } from "ant-table-extensions";
import {
  PageHeader,
  Button,
  Breadcrumb,
  DatePicker,
  Space,
  Select,
  Input,
  Switch,
  Checkbox,
} from "antd";
import { isMobile } from "react-device-detect";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import { settings } from "../../../config/appConfig";
import Highlighter from "react-highlight-words";
import { FormatData, FormatDataMobile } from "../../../library/numbers";
import moment from "moment";
// import { ReportPlayerVw } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function ReportPlayer() {

  const { Option } = Select;
  const { userId , role, username, compCode} = useSelector((state) => state.login);
  const CheckboxGroup = Checkbox.Group;
  const plainOptions = ['RegisterDateRange', 'FirstDepDateRange', 'LastDepDateRange', 'LastWthDateRange', 'LastActiveDateRange', 'Mobile', 'DepositStatus'];
  const defaultCheckedList = ['RegisterDateRange', 'FirstDepDateRange', 'LastDepDateRange', 'LastWthDateRange', 'LastActiveDateRange', 'Mobile', 'DepositStatus'];

  const [allSummaryReport, setAllSummaryReport] = useState("");

  const [tableLoading, setTableLoading] = useState(false);
  const [trxLoading, setTrxLoading] = useState(false);
  const [expand, setExpand]  = useState(false);

  const [todayDate, setTodayDate] = useState("");
  const [type, setType] = useState("selectDepositStatus");

  const [type1, setType1] = useState("thismonth");
  const [trxDateFrom1, setTrxDateFrom1] = useState("");
  const [trxDateTo1, setTrxDateTo1] = useState("");
  const reportDateFrom1 = useRef(null);
  const reportDateTo1 = useRef(null);

  const [type2, setType2] = useState("custom");
  const [trxDateFrom2, setTrxDateFrom2] = useState("");
  const [trxDateTo2, setTrxDateTo2] = useState("");
  const reportDateFrom2 = useRef(null);
  const reportDateTo2 = useRef(null);

  const [type3, setType3] = useState("custom");
  const [trxDateFrom3, setTrxDateFrom3] = useState("");
  const [trxDateTo3, setTrxDateTo3] = useState("");
  const reportDateFrom3 = useRef(null);
  const reportDateTo3 = useRef(null);

  const [type4, setType4] = useState("custom");
  const [trxDateFrom4, setTrxDateFrom4] = useState("");
  const [trxDateTo4, setTrxDateTo4] = useState("");
  const reportDateFrom4 = useRef(null);
  const reportDateTo4 = useRef(null);

  const [type5, setType5] = useState("custom");
  const [trxDateFrom5, setTrxDateFrom5] = useState("");
  const [trxDateTo5, setTrxDateTo5] = useState("");
  const reportDateFrom5 = useRef(null);
  const reportDateTo5 = useRef(null);

  const [type6, setType6] = useState("custom");
  const [trxDateFrom6, setTrxDateFrom6] = useState("");
  const [trxDateTo6, setTrxDateTo6] = useState("");
  const reportDateFrom6 = useRef(null);
  const reportDateTo6 = useRef(null);

  const [searchText, setSearchText] = useState("");
  const [searchMobile,setSearchMobile] = useState("");

  const [registerDateCheckbox, setRegisterDateCheckbox] = useState(false);
  const [firstDepDateCheckbox, setFirstDepDateCheckbox] = useState(false);
  const [lastDepDateCheckbox, setLastDepDateCheckbox] = useState(false);
  const [lastWthDateCheckbox, setLastWthDateCheckbox] = useState(false);
  const [lastActiveDateCheckbox, setLastActiveDateCheckbox] = useState(false);
  const [mobileFilterCheckbox, setMobileFilterCheckbox] = useState(false);
  const [depositStatusCheckbox, setDepositStatusCheckbox] = useState(false);

  // const [toResolveAmtTotal, setToResolveAmtTotal] = useState("");
  // const [commToBankAmtTotal, setCommToBankAmtTotal] = useState("");
  const [checkAll, setCheckAll] = useState(true);
  const [checkedList, setCheckedList] = useState("");

  const reportMobile = useRef(null);

  const [shareholderSelection, setShareholderSelection] = useState(null);
  const reportShareholderRef = useRef(null);
  const [shareholderOptions, setShareholderOptions] = useState([]);
  const [isAdminCS, setIsAdminCS] = useState(false);

  //Status Filter
  const [status, setStatus] = useState("All");

  useEffect(() => {
    shareholderList();
  }, [status]);

  useEffect(() => {
    if (role === "Admin_CS") {
      setIsAdminCS(true);
    } else {
      setIsAdminCS(false);
    }
  }, [role]);

  useEffect(()=> {
    setShareholderSelection(shareholderSelection);
  },[shareholderSelection]);

  useEffect(()=> {
    setTrxDateFrom1(trxDateFrom1);
    setTrxDateTo1(trxDateTo1);
  },[trxDateFrom1,trxDateTo1]);

  useEffect(()=> {
    setTrxDateFrom2(trxDateFrom2);
    setTrxDateTo2(trxDateTo2);
  },[trxDateFrom2,trxDateTo2]);

  useEffect(()=> {
    setTrxDateFrom3(trxDateFrom3);
    setTrxDateTo3(trxDateTo3);
  },[trxDateFrom3,trxDateTo3]);
  
  useEffect(()=> {
    setTrxDateFrom4(trxDateFrom4);
    setTrxDateTo4(trxDateTo4);
  },[trxDateFrom4,trxDateTo4]);

  useEffect(()=> {
    setTrxDateFrom5(trxDateFrom5);
    setTrxDateTo5(trxDateTo5);
  },[trxDateFrom5,trxDateTo5]);

  useEffect(()=> {
    setTrxDateFrom6(trxDateFrom6);
    setTrxDateTo6(trxDateTo6);
  },[trxDateFrom6,trxDateTo6]);

  const shareholderList = async () => {
    try{
        let requestData = {
            role : role,
            compCode : compCode,
            userId : userId
        }

        const response = await reportShareholderList(requestData);
        let result = await response.json();
        
        if (result.status === 'ok') {
            setShareholderOptions(result.msg != 'No record.' ? result.data : []);
        }
        else{
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }  
    }
    catch (error) {
        alertData.title = "Error";
        alertData.msg = "Failed to fetch shareholder list";
        sharedFailAlert(alertData);
    }
  }

  const onClickExportBtn = ()=> {

    if (allSummaryReport.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    document.getElementById("export-button-1").click();
  }

  const resetBtn = () => {
    // setAllReport([]);
    // setType("thismonth");
    window.location.reload();
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
        
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : false,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },
    render: (text) =>
      text ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        false
      ),
  });

  // const trxDatePicker = (date, dateString) => {
  //   setType("custom");
  //   setTrxDateFrom(date);
  // };

  // const trxDatePicker2 = (date, dateString) => {
  //   setType("custom");
  //   setTrxDateTo(date);
  // };

  const trxDatePicker11 = (date, dateString) => {
    setType1("custom");
    setTrxDateFrom1(date);
  };

  const trxDatePicker12 = (date, dateString) => {
    setType1("custom");
    setTrxDateTo1(date);
  };

  const trxDatePicker21 = (date, dateString) => {
    setType2("custom");
    setTrxDateFrom2(date);
  };

  const trxDatePicker22 = (date, dateString) => {
    setType2("custom");
    setTrxDateTo2(date);
  };

  const trxDatePicker31 = (date, dateString) => {
    setType3("custom");
    setTrxDateFrom3(date);
  };

  const trxDatePicker32 = (date, dateString) => {
    setType3("custom");
    setTrxDateTo3(date);
  };

  const trxDatePicker41 = (date, dateString) => {
    setType4("custom");
    setTrxDateFrom4(date);
  };

  const trxDatePicker42 = (date, dateString) => {
    setType4("custom");
    setTrxDateTo4(date);
  };

  const trxDatePicker51 = (date, dateString) => {
    setType5("custom");
    setTrxDateFrom5(date);
  };

  const trxDatePicker52 = (date, dateString) => {
    setType5("custom");
    setTrxDateTo5(date);
  };

  const trxDatePicker61 = (date, dateString) => {
    setType6("custom");
    setTrxDateFrom6(date);
  };

  const trxDatePicker62 = (date, dateString) => {
    setType6("custom");
    setTrxDateTo6(date);
  };

  // const reigisterDatePickerFrom = (date, dateString) => {
  //   setType2("custom");
  //   setRegisterDateRangeFrom(date);
  // };

  // const reigisterDatePickerTo = (date, dateString) => {
  //   setType2("custom");
  //   setRegisterDateRangeTo(date);
  // };

  // const firstTimeDepDatePickerFrom = (date, dateString) => {
  //   setFirstTimeDepDateRangeFrom(date);
  // };

  // const firstTimeDepDatePickerTo = (date, dateString) => {
  //   setFirstTimeDepDateRangeTo(date);
  // };

  // const lastDepDatePickerFrom = (date, dateString) => {
  //   setLastDepDateRangeFrom(date);
  // };

  // const lastDepDatePickerTo = (date, dateString) => {
  //   setLastDepDateRangeTo(date);
  // };
  
  // const lastWthDatePickerFrom = (date, dateString) => {
  //   setLastWthDateRangeFrom(date);
  // };

  // const lastWthDatePickerTo = (date, dateString) => {
  //   setLastWthDateRangeTo(date);
  // };

  // const lastActiveDatePickerFrom = (date, dateString) => {
  //   setLastActiveDateRangeFrom(date);
  // };

  // const lastActiveDatePickerTo = (date, dateString) => {
  //   setLastActiveDateRangeTo(date);
  // };

  const PickerWithType1 = ({ type, onChange }) => {
    if (type1 === 'custom'){
        setTrxDateFrom1(trxDateFrom1);
        setTrxDateTo1(trxDateTo1);
    }
    if (type1 === 'today'){
      let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
      setTrxDateFrom1(moment().set('date', today))
      setTrxDateTo1(moment().set('date', today))
    } 
    if (type1 === 'yesterday'){
      let yesterday = new Date(new Date().getTime());
      yesterday.setDate(yesterday.getDate()-1);
      let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
      let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
      let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
      yestMonth = yestMonth - 1

      setTrxDateFrom1(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
      setTrxDateTo1(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
    }
    if (type1 === 'thisweek'){
      let today = new Date();
      let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
      let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
      let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
          firstMonthOfWeek = firstMonthOfWeek - 1; 
      let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
      let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
      let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
          lastMonthOfWeek = lastMonthOfWeek - 1;
      setTrxDateFrom1(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
      setTrxDateTo1(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
    }
    if (type1 === 'thismonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})

      let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom1(moment().set({'year': year, 'month': month, 'date': 1}))
      setTrxDateTo1(moment().set({'year': year, 'month': month, 'date': lastDay}))
    }
    if (type1 === 'lastmonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m-1, 1);
      let lastDay = new Date(y, m, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
      let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})

      let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom1(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
      setTrxDateTo1(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
    }
    return <DatePicker picker={type1} onChange={onChange} />;
  };

  const PickerWithType2 = ({ type, onChange }) => {
    if (type2 === 'custom'){
        setTrxDateFrom2(trxDateFrom2);
        setTrxDateTo2(trxDateTo2);
    }
    if (type2 === 'today'){
      let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
      setTrxDateFrom2(moment().set('date', today))
      setTrxDateTo2(moment().set('date', today))
    } 
    if (type2 === 'yesterday'){
      let yesterday = new Date(new Date().getTime());
      yesterday.setDate(yesterday.getDate()-1);
      let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
      let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
      let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
      yestMonth = yestMonth - 1

      setTrxDateFrom2(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
      setTrxDateTo2(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
    }
    if (type2 === 'thisweek'){
      let today = new Date();
      let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
      let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
      let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
          firstMonthOfWeek = firstMonthOfWeek - 1; 
      let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
      let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
      let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
          lastMonthOfWeek = lastMonthOfWeek - 1;
      setTrxDateFrom2(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
      setTrxDateTo2(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
    }
    if (type2 === 'thismonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})

      let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom2(moment().set({'year': year, 'month': month, 'date': 1}))
      setTrxDateTo2(moment().set({'year': year, 'month': month, 'date': lastDay}))
    }
    if (type2 === 'lastmonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m-1, 1);
      let lastDay = new Date(y, m, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
      let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})

      let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom2(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
      setTrxDateTo2(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
    }
    return <DatePicker picker={type2} onChange={onChange} />;
  };

  const PickerWithType3 = ({ type, onChange }) => {
    if (type3 === 'custom'){
        setTrxDateFrom3(trxDateFrom3);
        setTrxDateTo3(trxDateTo3);
    }
    if (type3 === 'today'){
      let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
      setTrxDateFrom3(moment().set('date', today))
      setTrxDateTo3(moment().set('date', today))
    } 
    if (type3 === 'yesterday'){
      let yesterday = new Date(new Date().getTime());
      yesterday.setDate(yesterday.getDate()-1);
      let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
      let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
      let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
      yestMonth = yestMonth - 1

      setTrxDateFrom3(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
      setTrxDateTo3(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
    }
    if (type3 === 'thisweek'){
      let today = new Date();
      let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
      let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
      let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
          firstMonthOfWeek = firstMonthOfWeek - 1; 
      let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
      let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
      let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
          lastMonthOfWeek = lastMonthOfWeek - 1;
      setTrxDateFrom3(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
      setTrxDateTo3(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
    }
    if (type3 === 'thismonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})

      let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom3(moment().set({'year': year, 'month': month, 'date': 1}))
      setTrxDateTo3(moment().set({'year': year, 'month': month, 'date': lastDay}))
    }
    if (type3 === 'lastmonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m-1, 1);
      let lastDay = new Date(y, m, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
      let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})

      let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom3(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
      setTrxDateTo3(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
    }
    return <DatePicker picker={type3} onChange={onChange} />;
  };

  const PickerWithType4 = ({ type, onChange }) => {
    if (type4 === 'custom'){
        setTrxDateFrom4(trxDateFrom4);
        setTrxDateTo4(trxDateTo4);
    }
    if (type4 === 'today'){
      let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
      setTrxDateFrom4(moment().set('date', today))
      setTrxDateTo4(moment().set('date', today))
    } 
    if (type4 === 'yesterday'){
      let yesterday = new Date(new Date().getTime());
      yesterday.setDate(yesterday.getDate()-1);
      let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
      let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
      let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
      yestMonth = yestMonth - 1

      setTrxDateFrom4(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
      setTrxDateTo4(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
    }
    if (type4 === 'thisweek'){
      let today = new Date();
      let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
      let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
      let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
          firstMonthOfWeek = firstMonthOfWeek - 1; 
      let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
      let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
      let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
          lastMonthOfWeek = lastMonthOfWeek - 1;
      setTrxDateFrom4(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
      setTrxDateTo4(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
    }
    if (type4 === 'thismonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})

      let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom4(moment().set({'year': year, 'month': month, 'date': 1}))
      setTrxDateTo4(moment().set({'year': year, 'month': month, 'date': lastDay}))
    }
    if (type4 === 'lastmonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m-1, 1);
      let lastDay = new Date(y, m, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
      let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})

      let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom4(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
      setTrxDateTo4(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
    }
    return <DatePicker picker={type4} onChange={onChange} />;
  };

  const PickerWithType5 = ({ type, onChange }) => {
    if (type5 === 'custom'){
        setTrxDateFrom5(trxDateFrom5);
        setTrxDateTo5(trxDateTo5);
    }
    if (type5 === 'today'){
      let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
      setTrxDateFrom5(moment().set('date', today))
      setTrxDateTo5(moment().set('date', today))
    } 
    if (type5 === 'yesterday'){
      let yesterday = new Date(new Date().getTime());
      yesterday.setDate(yesterday.getDate()-1);
      let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
      let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
      let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
      yestMonth = yestMonth - 1

      setTrxDateFrom5(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
      setTrxDateTo5(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
    }
    if (type5 === 'thisweek'){
      let today = new Date();
      let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
      let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
      let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
          firstMonthOfWeek = firstMonthOfWeek - 1; 
      let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
      let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
      let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
          lastMonthOfWeek = lastMonthOfWeek - 1;
      setTrxDateFrom5(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
      setTrxDateTo5(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
    }
    if (type5 === 'thismonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})

      let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom5(moment().set({'year': year, 'month': month, 'date': 1}))
      setTrxDateTo5(moment().set({'year': year, 'month': month, 'date': lastDay}))
    }
    if (type5 === 'lastmonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m-1, 1);
      let lastDay = new Date(y, m, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
      let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})

      let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom5(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
      setTrxDateTo5(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
    }
    return <DatePicker picker={type5} onChange={onChange} />;
  };

  const PickerWithType6 = ({ type, onChange }) => {
    if (type6 === 'custom'){
        setTrxDateFrom6(trxDateFrom6);
        setTrxDateTo6(trxDateTo6);
    }
    if (type6 === 'today'){
      let today = new Date().toLocaleDateString("en-CA", {day: "numeric"});
      setTrxDateFrom6(moment().set('date', today))
      setTrxDateTo6(moment().set('date', today))
    } 
    if (type6 === 'yesterday'){
      let yesterday = new Date(new Date().getTime());
      yesterday.setDate(yesterday.getDate()-1);
      let yestDay = yesterday.toLocaleDateString("en-CA", {day: "numeric"});
      let yestMonth = yesterday.toLocaleDateString("en-CA", {month: "numeric"});
      let yestYear = yesterday.toLocaleDateString("en-CA", {year: "numeric"});
      yestMonth = yestMonth - 1

      setTrxDateFrom6(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
      setTrxDateTo6(moment().set({'year': yestYear, 'month': yestMonth, 'date': yestDay}))
    }
    if (type6 === 'thisweek'){
      let today = new Date();
      let firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
      let firstDayOfWeek = firstDay.toLocaleDateString("en-CA", {day: "numeric"});
      let firstMonthOfWeek = firstDay.toLocaleDateString("en-CA", {month: "numeric"});
      let firstYearOfWeek = firstDay.toLocaleDateString("en-CA", {year: "numeric"});
          firstMonthOfWeek = firstMonthOfWeek - 1; 
      let lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 7));
      let lastDayOfWeek = lastDay.toLocaleDateString("en-CA", {day: "numeric"});
      let lastMonthOfWeek = lastDay.toLocaleDateString("en-CA", {month: "numeric"});
      let lastYearOfWeek = lastDay.toLocaleDateString("en-CA", {year: "numeric"});
          lastMonthOfWeek = lastMonthOfWeek - 1;
      setTrxDateFrom6(moment().set({'year': firstYearOfWeek, 'month': firstMonthOfWeek, 'date': firstDayOfWeek}));
      setTrxDateTo6(moment().set({'year': lastYearOfWeek, 'month': lastMonthOfWeek, 'date': lastDayOfWeek}));
    }
    if (type6 === 'thismonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m, 1);
      let lastDay = new Date(y, m + 1, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})

      let year = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom6(moment().set({'year': year, 'month': month, 'date': 1}))
      setTrxDateTo6(moment().set({'year': year, 'month': month, 'date': lastDay}))
    }
    if (type6 === 'lastmonth'){
      let date = new Date(), y = date.getFullYear(), m = date.getMonth();
      let firstDay = new Date(y, m-1, 1);
      let lastDay = new Date(y, m, 0);

      let month = firstDay.toLocaleDateString("en-CA", {month: "numeric"})
      let yearOfFirstDay = firstDay.toLocaleDateString("en-CA", {year: "numeric"})

      let yearOfLastDay = lastDay.toLocaleDateString("en-CA", {year: "numeric"})
      lastDay = lastDay.toLocaleDateString("en-CA", {day: "numeric"})
      month = month - 1

      setTrxDateFrom6(moment().set({'year': yearOfFirstDay, 'month': month, 'date': 1}))
      setTrxDateTo6(moment().set({'year': yearOfLastDay, 'month': month, 'date': lastDay}))
    }
    return <DatePicker picker={type6} onChange={onChange} />;
  };

  const searchBar = () => {

    if (role === 'Admin_CS' && !shareholderSelection) {
      reportShareholderRef.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select a shareholder !";
      sharedErrorAlert(alertData);
      return;
    }
    
    if (!trxDateFrom1) {
      reportDateFrom1.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date From !";
      sharedErrorAlert(alertData);
      return;
    }

    if (!trxDateTo1) {
      reportDateTo1.current.focus();
      alertData.title = "Field Required";
      alertData.msg = "Please select Date To !";
      sharedErrorAlert(alertData);
      return;
    }

    if (trxDateTo1 < trxDateFrom1 || trxDateFrom1 > trxDateTo1) {
      alertData.title = "Error";
      alertData.msg = "Please select a valid Date Range !";
      sharedErrorAlert(alertData);
      return;
    }

    setSearchText("");
    setTrxLoading(true);
    reportallReportList();
  }

  const expandButton = (record) => {

    if(expand == false){
      setExpand(true);

    }else if (expand == true){
      setExpand(false);
    }
  }

  const onChange = (list) => {
    setCheckedList(list);

    setCheckAll(list.length === plainOptions.length);
    if(list.includes('RegisterDateRange')){
      setRegisterDateCheckbox(true);
      setTrxDateFrom2("");
      // setDepositChange(depositAmtTotal)

    }else if(!list.includes('RegisterDateRange')){
      setRegisterDateCheckbox(false);
      setTrxDateTo2("");
      // setDepositChange(0)
    }
    
    // if(list.includes('Comm2Wallet')){
    //   setComm2WalletCheckbox(true);
    //   setComm2WalletChange(commToWalletAmtTotal);
    // }else if(!list.includes('Comm2Wallet')){
    //   setComm2WalletCheckbox(false);
    //   setComm2WalletChange(0);
    // }

    if(list.includes('FirstDepDateRange')){
      setFirstDepDateCheckbox(true);
    }else if(!list.includes('FirstDepDateRange')){
      setFirstDepDateCheckbox(false);
    }

    if(list.includes('LastDepDateRange')){
      setLastDepDateCheckbox(true);
    }else if(!list.includes('LastDepDateRange')){
      setLastDepDateCheckbox(false);
    }

    if(list.includes('LastWthDateRange')){
      setLastWthDateCheckbox(true);
    }else if(!list.includes('LastWthDateRange')){
      setLastWthDateCheckbox(false);
    }

    if(list.includes('LastActiveDateRange')){
      setLastActiveDateCheckbox(true);
    }else if(!list.includes('LastActiveDateRange')){
      setLastActiveDateCheckbox(false);
    }

    if(list.includes('Mobile')){
      setMobileFilterCheckbox(true);
    }else if(!list.includes('Mobile')){
      setMobileFilterCheckbox(false);
    }

    if(list.includes('DepositStatus')){
      setDepositStatusCheckbox(true);
    }else if(!list.includes('DepositStatus')){
      setDepositStatusCheckbox(false);
    }
  };

  const reportallReportList = () => {
    setTableLoading(true);
    var count = 1;

    let thisID = '';
    if      (role === 'Admin_CS')   { thisID = shareholderSelection; }
    else if (role === 'Shareholder'){ thisID = userId; }

    let requestData = {
      datefrom: moment(trxDateFrom1).format("YYYY-MM-DD"),
      dateto: moment(trxDateTo1).format("YYYY-MM-DD"),
      mobile: searchMobile,
      depositStatus: type,
      registerDateFrom: moment(trxDateFrom2).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(trxDateFrom2).format("YYYY-MM-DD"),
      registerDateTo: moment(trxDateTo2).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(trxDateTo2).format("YYYY-MM-DD"),
      firstDepDateFrom: moment(trxDateFrom3).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(trxDateFrom3).format("YYYY-MM-DD"),
      firstDepDateTo: moment(trxDateTo3).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(trxDateTo3).format("YYYY-MM-DD"),
      lastDepDateFrom: moment(trxDateFrom4).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(trxDateFrom4).format("YYYY-MM-DD"),
      lastDepDateTo: moment(trxDateTo4).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(trxDateTo4).format("YYYY-MM-DD"),
      lastWthDateFrom: moment(trxDateFrom5).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(trxDateFrom5).format("YYYY-MM-DD"),
      lastWthDateTo: moment(trxDateTo5).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(trxDateTo5).format("YYYY-MM-DD"),
      lastActiveDateFrom: moment(trxDateFrom6).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(trxDateFrom6).format("YYYY-MM-DD"),
      lastActiveDateTo: moment(trxDateTo6).format("YYYY-MM-DD") == "Invalid date" ? "" : moment(trxDateTo6).format("YYYY-MM-DD"),
      role: role,
      userid: thisID,
    }

    reportPlayerShareholderV3(requestData)
      .then((response) => response.json())
      .then((json) => {
        console.log("json -----", json);
        if (json.status === "ok") {
        const allReport = Object.keys(json.data.reportPlayerData).length
        ? json.data.reportPlayerData.map((data) => {
            setTableLoading(false);
            return {
              key                     : count++,
              TrxnDate                : data.TrxnDate,
              MobileNumber            : data.MobileNumber,
              Fullname                : data.Fullname,
              RegisteredDate          : data.RegisteredDate,
              FirstDepDate            : data.FirstDepDate,
              LastDepDate             : data.LastDepDate,
              LastWthDate             : data.LastWthDate,
              LastActiveDate          : data.LastActiveDate,
              DepositAmount           : data.DepositAmount,
              DepositCount            : data.DepositCount,
              DepositCount            : data.DepositCount,
              UniqueLifetime          : data.UniqueLifetime,
              InactiveDep             : data.InactiveDep,
              MinDepAmount            : data.MinDepAmount,
              MaxDepAmount            : data.MaxDepAmount,
              WithdrawAmount          : data.WithdrawAmount,
              WithdrawCount           : data.WithdrawCount,
              LastWthAmt              : data.LastWthAmt,
              PromoAmt                : data.PromoAmt,
              PromoCount              : data.PromoCount,
              FortuneWheelAmt         : data.FortuneWheelAmt,
              FortuneWheelCount       : data.FortuneWheelCount,
              InviteAngpaoAmt         : data.InviteAngpaoAmt,
              InviteAngpaoCount       : data.InviteAngpaoCount,
              PermanentAngpaoAmt      : data.PermanentAngpaoAmt,
              PermanentAngpaoCount    : data.PermanentAngpaoCount,
              MissionAmt              : data.MissionAmt,
              MissionCount            : data.MissionCount,
              LastPlayGame            : data.LastPlayGame,
              AffiliateComm           : data.AffiliateComm,
              DirectUpline            : data.DirectUpline,
              DirectAgent             : data.DirectAgent,
              ProfitLossExcludeBonus : data.ProfitLossExcludeBonus,
            };
          })
          : [];

            let summaryDepositAmount = 0, summaryDepositCount = 0, summaryWithdrawAmount = 0, summaryWithdrawCount = 0, summaryLastWthAmt = 0;
            let summaryPromoAmt = 0, summaryPromoCount = 0, summaryFortuneWheelAmt = 0, summaryFortuneWheelCount = 0;
            let summaryInviteAngpaoAmt = 0, summaryInviteAngpaoCount = 0, summaryPermanentAngpaoAmt = 0, summaryPermanentAngpaoCount = 0;
            let summaryMissionAmt = 0, summaryMissionCount = 0, summaryAffiliateComm = 0, summaryProfitLossExcludeBonus = 0;
            let reportPlayerSummary = [...allReport];

            reportPlayerSummary.forEach(({DepositAmount, DepositCount, WithdrawAmount, WithdrawCount, LastWthAmt,
                                          PromoAmt, PromoCount, FortuneWheelAmt, FortuneWheelCount,
                                          InviteAngpaoAmt, InviteAngpaoCount, PermanentAngpaoAmt, PermanentAngpaoCount,
                                          MissionAmt, MissionCount, AffiliateComm, ProfitLossExcludeBonus
            }) => {
              summaryDepositAmount          += Number(FormatDataMobile(DepositAmount));
              summaryDepositCount           += Number(FormatDataMobile(DepositCount));
              summaryWithdrawAmount         += Number(FormatDataMobile(WithdrawAmount));
              summaryWithdrawCount          += Number(FormatDataMobile(WithdrawCount));
              summaryLastWthAmt             += Number(FormatDataMobile(LastWthAmt));
              summaryPromoAmt               += Number(FormatDataMobile(PromoAmt));
              summaryPromoCount             += Number(FormatDataMobile(PromoCount));
              summaryFortuneWheelAmt        += Number(FormatDataMobile(FortuneWheelAmt));
              summaryFortuneWheelCount      += Number(FormatDataMobile(FortuneWheelCount));
              summaryInviteAngpaoAmt        += Number(FormatDataMobile(InviteAngpaoAmt));
              summaryInviteAngpaoCount      += Number(FormatDataMobile(InviteAngpaoCount));
              summaryPermanentAngpaoAmt     += Number(FormatDataMobile(PermanentAngpaoAmt));
              summaryPermanentAngpaoCount   += Number(FormatDataMobile(PermanentAngpaoCount));
              summaryMissionAmt             += Number(FormatDataMobile(MissionAmt));
              summaryMissionCount           += Number(FormatDataMobile(MissionCount));
              summaryAffiliateComm          += Number(FormatDataMobile(AffiliateComm));
              summaryProfitLossExcludeBonus += Number(FormatDataMobile(ProfitLossExcludeBonus));
            });

            reportPlayerSummary= [
                {
                  key: "Summary",
                  TrxnDate                : "",
                  MobileNumber            : "",
                  Fullname                : "",
                  RegisteredDate          : "",
                  FirstDepDate            : "",
                  LastDepDate             : "",
                  LastWthDate             : "",
                  LastActiveDate          : "",
                  DepositAmount           : FormatDataMobile(summaryDepositAmount),
                  DepositCount            : summaryDepositCount,
                  MinDepAmount            : "",
                  MaxDepAmount            : "",
                  WithdrawAmount          : FormatDataMobile(summaryWithdrawAmount),
                  WithdrawCount           : summaryWithdrawCount,
                  LastWthAmt              : "",
                  PromoAmt                : FormatDataMobile(summaryPromoAmt),
                  PromoCount              : summaryPromoCount,
                  FortuneWheelAmt         : FormatDataMobile(summaryFortuneWheelAmt),
                  FortuneWheelCount       : summaryFortuneWheelCount,
                  InviteAngpaoAmt         : FormatDataMobile(summaryInviteAngpaoAmt),
                  InviteAngpaoCount       : summaryFortuneWheelCount,
                  PermanentAngpaoAmt      : FormatDataMobile(summaryPermanentAngpaoAmt),
                  PermanentAngpaoCount    : summaryPermanentAngpaoCount,
                  MissionAmt              : FormatDataMobile(summaryMissionAmt),
                  MissionCount            : summaryMissionCount,
                  LastPlayGame            : "",
                  AffiliateComm           : FormatDataMobile(summaryAffiliateComm),
                  DirectUpline            : "",
                  DirectAgent             : "",
                  ProfitLossExcludeBonus  : FormatDataMobile(summaryProfitLossExcludeBonus),
                },
                ...reportPlayerSummary,
              ];
            
            let todayDate = new Date().toLocaleDateString('en-GB').split('/').reverse().join('-');
            setTodayDate(todayDate);
            setAllSummaryReport(reportPlayerSummary);

          if (allReport.length == 0 ) {
            // alertData.title = "Error";
            // alertData.msg = "No data !";
            // sharedErrorAlert(alertData);
            setTableLoading(false);
            return;
          }
        } 
        else {
          alertData.title = "Error";
          alertData.msg = json.msg;
          sharedErrorAlert(alertData);
          setTableLoading(false);
        }        
      });      
  };

  const columns = [
        {
          align: "center",
          title: "No",
          dataIndex: "key",
          width: 100,
          fixed:"left",
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          }
        },
        {
          align: "center",
          title: "Date",
          dataIndex: "TrxnDate",
          width: 130,
          fixed:"left",
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          }
        },
        {
          align: "center",
          title: "Mobile",
          dataIndex: "MobileNumber",
          width: 130,
          fixed:"left",
          style: [{"word-wrap":"break-word"}],
          ...getColumnSearchProps("MobileNumber"),
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          },
        },
        {
          align: "center",
          title: "Name",
          dataIndex: "Fullname",
          width: 180,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          },
          ...getColumnSearchProps("Fullname"),
        },
        {
          align: "center",
          title: "Register Date",
          dataIndex: "RegisteredDate",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          }
        },
        {
          align: "center",
          title: "First Deposit Date",
          dataIndex: "FirstDepDate",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{record}</b>: record)
          }
        },
        {
          align: "center",
          title: "Last Deposit Date",
          dataIndex: "LastDepDate",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{record}</b>: record)
          }
        },
        {
          align: "center",
          title: "Unique Lifetime (DAY)",
          dataIndex: "UniqueLifetime",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          },
        },
        {
          align: "center",
          title: "Inactive Deposit (DAY)",
          dataIndex: "InactiveDep",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          },
        },
        {
          align: "center",
          title: "Last Withdraw Date",
          dataIndex: "LastWthDate",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{record}</b>: record)
          }
        },
        {
          align: "center",
          title: "Last Active Date",
          dataIndex: "LastActiveDate",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          }
        },
        {
          align: "center",
          title: "Total Deposit",
          dataIndex: "DepositAmount",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{FormatDataMobile(record)}</b>
                    : <span>{Number(record).toFixed(2)}<span style={{color:"blue"}}> &#x5B;<b>{record2.DepositCount}</b>&#93;</span>
                      </span>
                   )
          },
        },
        {
          align: "center",
          title: "Lifetime Min Deposit",
          dataIndex: "MinDepAmount",
          width: expand == true ? 170 : 0,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:Number(record).toFixed(2))
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Lifetime Max Deposit",
          dataIndex: "MaxDepAmount",
          width: expand == true ? 180 : 0,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:Number(record).toFixed(2))
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Number of Deposit",
          dataIndex: "DepositCount",
          width: 0,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Total Withdraw",
          dataIndex: "WithdrawAmount",
          width: 140,
          style: [{"word-wrap":"break-word"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{FormatDataMobile(record)}</b>
                    : <span>{Number(record).toFixed(2)}<span style={{color:"blue"}}> &#x5B;<b>{record2.WithdrawCount}</b>&#93;</span>
                      </span>
                   )
          },
        },
        {
          align: "center",
          title: "Number of Withdraw",
          dataIndex: "WithdrawCount",
          width: 0,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Last Withdraw",
          dataIndex: "LastWthAmt",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:Number(record).toFixed(2))
          }
        },
        {
          align: "center",
          title: "Bonus",
          dataIndex: "PromoAmt",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{FormatDataMobile(record)}</b>
                    : <span style={{color:"#339966"}}><b>{Number(record).toFixed(2)}</b><span style={{color:"blue"}}> &#x5B;<b>{record2.PromoCount}</b>&#93;</span>
                      </span>
                   )
          },
        },
        {
          align: "center",
          title: "Number of bonus",
          dataIndex: "PromoCount",
          width: 0,
          headStyles:[{"font-size":"2px"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Fortune Wheel Amount", 
          dataIndex: "FortuneWheelAmt",
          width: expand == true ? 190 : 0,
          headStyles:[{"font-size":"2px"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{FormatDataMobile(record)}</b>
                    : <span style={{color:"#40bf80"}}>{Number(record).toFixed(2)}<span style={{color:"blue"}}> &#x5B;<b>{record2.FortuneWheelCount}</b>&#93;</span>
                      </span>
                   )
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Fortune Wheel Count", 
          dataIndex: "FortuneWheelCount",
          width: 0,
          headStyles:[{"font-size":"2px"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Invite Angpao Amount", 
          dataIndex: "InviteAngpaoAmt",
          width: expand == true ? 180 : 0,
          headStyles:[{"font-size":"2px"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{FormatDataMobile(record)}</b>
                    : <span style={{color:"#40bf80"}}>{Number(record).toFixed(2)}<span style={{color:"blue"}}> &#x5B;<b>{record2.InviteAngpaoCount}</b>&#93;</span>
                      </span>
                   )
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Invite Angpao Count", 
          dataIndex: "InviteAngpaoCount",
          width: 0,
          headStyles:[{"font-size":"2px"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Permanent Angpao Amount", 
          dataIndex: "PermanentAngpaoAmt",
          width: expand == true ? 220 : 0,
          headStyles:[{"font-size":"2px"}],
          style: [{"word-wrap":"break-word"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{FormatDataMobile(record)}</b>
                    : <span style={{color:"#40bf80"}}>{Number(record).toFixed(2)}<span style={{color:"blue"}}> &#x5B;<b>{record2.PermanentAngpaoCount}</b>&#93;</span>
                      </span>
                   )
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Permanent Angpao Count", 
          dataIndex: "PermanentAngpaoCount",
          width: 0,
          headStyles:[{"font-size":"2px"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Invite Mission Amount(50 Baht)", 
          dataIndex: "MissionAmt",
          width: expand == true ? 250 : 0,
          headStyles:[{"font-size":"2px"}],
          style: [{"word-wrap":"break-word"}],
          render:function(record, record2) {
            return (record === "Summary" ? <b>{FormatDataMobile(record)}</b>
                    : <span style={{color:"#40bf80"}}>{Number(record).toFixed(2)}<span style={{color:"blue"}}> &#x5B;<b>{record2.MissionCount}</b>&#93;</span>
                      </span>
                   )
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Invite Mission Count", 
          dataIndex: "MissionCount",
          width: 0,
          headStyles:[{"font-size":"2px"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          },
          ellipsis: true,
        },
        {
          align: "center",
          title: "Last Played",
          dataIndex: "LastPlayGame",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          }
        },
        {
          align: "center",
          title: "Affiliate Income",
          dataIndex: "AffiliateComm",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? <b>{record}</b>:(record))
          }
        },
        {
          align: "center",
          title: "Direct Upline",
          dataIndex: "DirectUpline",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? '' : record == '' ? '-' : record)
          }
        },
        {
          align: "center",
          title: "First-Gen Upline ",
          dataIndex: "DirectAgent",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? '' : record == '' ? '-' : record)
          }
        },
        {
          align: "center",
          title: "Profit/Loss not excluding bonus ",
          dataIndex: "ProfitLossExcludeBonus",
          width: 130,
          style: [{"word-wrap":"break-word"}],
          render:function(record) {
            return (record === "Summary" ? <b>{FormatDataMobile(record)}</b>:Number(record).toFixed(2))
          }
        },
      ];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    if (allSummaryReport.length === 0 ) {
      alertData.title = "Error";
      alertData.msg = "No data to download !";
      sharedErrorAlert(alertData);
      return;
    }

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(10);
    let curDate = new Date();

    const title = `Player Report (${moment(curDate).format('YYYY-MM-DD')})\n\n\n`;

    const headers = [
      [
        columns[0].title,
        columns[1].title,
        columns[2].title,
        columns[3].title,
        columns[4].title,
        columns[5].title,
        columns[6].title,
        columns[7].title,
        columns[8].title,
        columns[9].title,
        columns[10].title,
        columns[11].title,
        columns[12].title,
        columns[13].title,
        columns[14].title,
        columns[15].title,
        columns[16].title,
        columns[17].title,
        columns[18].title,
        columns[19].title,
        columns[20].title,
        columns[21].title,
        columns[22].title,
        columns[23].title,
        columns[24].title,
      ],
    ]; 

    var filteredReport = [...allSummaryReport];
    const data = filteredReport.map((data) => {
      return [
        data[columns[0].dataIndex],
        data[columns[1].dataIndex],
        data[columns[2].dataIndex],
        data[columns[3].dataIndex],
        data[columns[4].dataIndex],
        data[columns[5].dataIndex],
        data[columns[6].dataIndex],
        data[columns[7].dataIndex],
        data[columns[8].dataIndex],
        FormatDataMobile(data[columns[9].dataIndex]),
        FormatDataMobile(data[columns[10].dataIndex]),
        data[columns[11].dataIndex],
        FormatDataMobile(data[columns[12].dataIndex]),
        data[columns[13].dataIndex],
        FormatDataMobile(data[columns[14].dataIndex]),
        FormatDataMobile(data[columns[15].dataIndex]),
        data[columns[16].dataIndex],
        data[columns[17].dataIndex],
        FormatDataMobile(data[columns[18].dataIndex]),
        FormatDataMobile(data[columns[19].dataIndex]),
        FormatDataMobile(data[columns[20].dataIndex]),
        FormatDataMobile(data[columns[21].dataIndex]),
        FormatDataMobile(data[columns[22].dataIndex]),
        FormatDataMobile(data[columns[23].dataIndex]),
        data[columns[24].dataIndex],

      ];
    });

    let content = {
      startY: 80,
      head: headers,
      body: data,
      headStyles: { fillColor: "#1DA57A"},
      styles:{fontSize:7}
    };


    doc.text(title, marginLeft, 40);

    doc.autoTable(content);   
    doc.save(`Player Report.pdf`);
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Player Report",
    },
    {
      //   path: "first",
      breadcrumbName: "Report",
    },
    {
      path: "commBalanceReport",
      breadcrumbName: "Player Report",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 2;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const styles = {
    tableContainer: {
      backgroundColor: "#FFFFFF",
      padding: 24,
      marginTop: -30,
    },
    expandStyle: {
      marginLeft: 20,
    },
    checkbox: {
      paddingTop: 10,
      paddingLeft: 10,
      marginBottom: -20,
    },
    inlineBlock: {
      display: "inline-block",
    },
    paginationStyle: {
      marginTop: 50,
    },
    pageHeaderContainer: {
      justifyContent: isMobile ? "" : "space-between",
      // display: "flex",
      flexDirection: isMobile ? "column" : "",
      alignItems: isMobile ? "center" : "",
      display: "inline",
    },
    resetBtn: {
      marginLeft: 10,
    },
    inline: {
      display: "inline",
    },
    inlineFlex: {
      display: "flex",
    },
    dateRange: {
      marginLeft: 10,
    },
    hide: {
      marginLeft: 10000,
    },
    datePickerStyle: {
      width: 280,
    },
    datePickerSelectionStyle: {
      marginLeft: 10,
      width: 120,
    },
    mobileSelectionStyle: {
      marginLeft: 10,
      width: 280,
    },
    depositStatusStyle: {
      marginLeft: 10,
      width: 280,
    },
    refreshBtn: {
      marginLeft: 10,
    },
    headStyles: {
      backgroundColor: "#e2f2f0",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      fontSize: 20,
      height: 20,
    },
    bodyStyle: {
      backgroundColor: "#e2f2f0",
      paddingLeft: 30,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
      justifyContent: "flex-start",
      display: "flex",
      height: 60,
    },
    tagStyle:{
      width:"100px",
    },
    memberBtn: {
      marginTop:"10px",
      width:"150px", 
      marginBottom:"8px"
    }, 
  };

  return (
    <div>
      {
        
        // ReportPlayerVw(role,username,compCode)?
        
        <div className="eachPageContent">
        {isMobile ? (
          
          <div>
            <PageHeader ghost={false}>
              <div>
                <Breadcrumb
                  separator=">"
                  itemRender={itemRender}
                  routes={routes}
                />
                <div style={styles.tableContainer}>
                  <Table
                    columns={columns}
                    dataSource={allSummaryReport}
                    pagination={{
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20"],
                      style: styles.paginationStyle,
                    }}
                  />
                </div>
              </div>
            </PageHeader>
          </div>
        ) : (
          <div>
            <PageHeader
              ghost={false}
              // title="Player Report"
            >
              <div style={styles.pageHeaderContainer}>
                {/* <Button
                  style={styles.refreshBtn}
                  type="primary"
                  onClick={() => {
                    // reportList();
                    window.location.reload();
                  }}
                >
                  Refresh Page
                </Button> */}
                {isAdminCS && (
                  <Select
                    showSearch
                    value={shareholderSelection}
                    optionFilterProp="children"
                    ref={reportShareholderRef}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder="Please select a shareholder"
                    style={{width: '230px'}}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(value) => {
                      setShareholderSelection(value);
                    }}
                  >
                    {shareholderOptions.map((option) => {
                      return (
                        <Option key={option.key} value={option.shareholder}>
                          {option.username}
                        </Option>
                      );
                    })}
                  </Select>
                )}
                <DatePicker
                    key="1"
                    style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                    format="YYYY-MM-DD"
                    onChange={trxDatePicker11}
                    value={trxDateFrom1}
                    ref={reportDateFrom1}
                    refs
                    placeholder={"Select Date From"}
                    disabledDate={(current)=> {
                      return current > moment();//.subtract(1, 'day');
                    }}
                />
                <DatePicker
                  key="2"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker12}
                  value={trxDateTo1}
                  ref={reportDateTo1}
                  placeholder={"Select Date To"}
                  disabledDate={(current)=> {
                    let dateFrom = trxDateFrom1;
                    if (dateFrom) {
                      return current < dateFrom || current > moment();//.subtract(1, 'day');
                    }else {
                      return  current > moment();//.subtract(1, 'day');
                    }
                  }}
                />
                {/* <Input
                  type="text"
                  id="searchMobileNo"
                  placeholder="Enter Mobile"
                  style={{...styles.mobileSelectionStyle}}
                  onChange={(e) => {
                      setSearchMobile(e.target.value.replace(/\D|^0/g, ""));                
                  }}
                  allowClear={true}
                  value={searchMobile}
                  ref={reportMobile}
                  >
                  </Input> */}
                <div style={styles.inlineBlock}>
                  <Space>
                    <Select style={{...styles.datePickerSelectionStyle}} value={type1} type={type1} onChange={setType1}>
                      <Option value="custom">Custom</Option>
                      <Option value="today">Today</Option>
                      <Option value="yesterday">Yesterday</Option>
                      <Option value="thisweek">This Week</Option>
                      <Option value="thismonth">This Month</Option>
                      <Option value="lastmonth">Last Month</Option>
                    </Select>
                  </Space>
                  <Button
                    type={"primary"}
                    icon={<SearchOutlined
                    onClick={searchBar}
                    />}
                  />
                  <Button style={{...styles.resetBtn,...styles.refreshBtn}} onClick={resetBtn}>
                    Reset
                  </Button>
                </div>
                <Button 
                      type={"primary"} 
                      style={styles.refreshBtn} 
                      icon={<FileExcelOutlined />}
                      onClick={() => {
                          onClickExportBtn();
                      }}
                      
                  >
                    Export to Excel
                </Button>
                <span style={styles.expandStyle}>Expandable:  <Switch  checkedChildren="ON" unCheckedChildren="OFF" onClick={(event) => { expandButton() }}/></span>
              </div>
              <div style={styles.checkbox}>
                <p>
                  <b>Filter:</b> <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                </p>
              </div>
              {registerDateCheckbox == true ?
                <br></br>
              : ""}
              {registerDateCheckbox == true ?
                <DatePicker
                  key="1"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker21}
                  value={trxDateFrom2}
                  ref={reportDateFrom2}
                  refs
                  placeholder={"Select Register Date From"}
                  disabledDate={(current)=> {
                    return current > moment();//.subtract(1, 'day');
                  }}
                />
              : ""}
              {registerDateCheckbox == true ?
                <DatePicker
                  key="2"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker22}
                  value={trxDateTo2}
                  ref={reportDateTo2}
                  placeholder={"Select Register Date To"}
                  disabledDate={(current)=> {
                    let dateFrom = trxDateFrom2;
                    if (dateFrom) {
                      return current < dateFrom || current > moment();//.subtract(1, 'day');
                    }else {
                      return  current > moment();//.subtract(1, 'day');
                    }
                  }}
                />
              : ""}
              {registerDateCheckbox == true ?
                <Space>
                  <Select style={{...styles.datePickerSelectionStyle}} value={type2} type={type2} onChange={setType2}>
                    <Option value="custom">Custom</Option>
                    <Option value="today">Today</Option>
                    <Option value="yesterday">Yesterday</Option>
                    <Option value="thisweek">This Week</Option>
                    <Option value="thismonth">This Month</Option>
                    <Option value="lastmonth">Last Month</Option>
                  </Select>
                </Space>
              : ""}
              {registerDateCheckbox == true ?
                <div style={{...styles.inline, ...styles.dateRange}}>
                  Register Date Range
                </div>
              : ""}
              {firstDepDateCheckbox == true ?
                <br></br>
              : ""}
              {firstDepDateCheckbox == true ?
                <DatePicker
                  key="3"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker31}
                  value={trxDateFrom3}
                  ref={reportDateFrom3}
                  refs
                  placeholder={"Select First Time Dep Date From"}
                  disabledDate={(current)=> {
                    return current > moment();//.subtract(1, 'day');
                  }}
                />
              : ""}
              {firstDepDateCheckbox == true ?
                <DatePicker
                  key="4"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker32}
                  value={trxDateTo3}
                  ref={reportDateTo3}
                  placeholder={"Select First Time Dep Date To"}
                  disabledDate={(current)=> {
                    let dateFrom = trxDateFrom3;
                    if (dateFrom) {
                      return current < dateFrom || current > moment();//.subtract(1, 'day');
                    }else {
                      return  current > moment();//.subtract(1, 'day');
                    }
                  }}
                />
              : ""}
              {firstDepDateCheckbox == true ?
                <Space>
                  <Select style={{...styles.datePickerSelectionStyle}} value={type3} type={type3} onChange={setType3}>
                    <Option value="custom">Custom</Option>
                    <Option value="today">Today</Option>
                    <Option value="yesterday">Yesterday</Option>
                    <Option value="thisweek">This Week</Option>
                    <Option value="thismonth">This Month</Option>
                    <Option value="lastmonth">Last Month</Option>
                  </Select>
                </Space>
              : ""}
              {firstDepDateCheckbox == true ?
                <div style={{...styles.inline, ...styles.dateRange}}>
                  First Time Deposit Date Range
                </div>
              : ""}
              {lastDepDateCheckbox == true ?
                <br></br>
              : ""}
              {lastDepDateCheckbox == true ?
                <DatePicker
                  key="5"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker41}
                  value={trxDateFrom4}
                  ref={reportDateFrom4}
                  refs
                  placeholder={"Select Last Dep Date From"}
                  disabledDate={(current)=> {
                    return current > moment();//.subtract(1, 'day');
                  }}
                />
              : ""}
              {lastDepDateCheckbox == true ?
                <DatePicker
                  key="6"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker42}
                  value={trxDateTo4}
                  ref={reportDateTo4}
                  placeholder={"Select Last Dep Date To"}
                  disabledDate={(current)=> {
                    let dateFrom = trxDateFrom4;
                    if (dateFrom) {
                      return current < dateFrom || current > moment();//.subtract(1, 'day');
                    }else {
                      return  current > moment();//.subtract(1, 'day');
                    }
                  }}
                />
              : ""}
              {lastDepDateCheckbox == true ?
                <Space>
                  <Select style={{...styles.datePickerSelectionStyle}} value={type4} type={type4} onChange={setType4}>
                    <Option value="custom">Custom</Option>
                    <Option value="today">Today</Option>
                    <Option value="yesterday">Yesterday</Option>
                    <Option value="thisweek">This Week</Option>
                    <Option value="thismonth">This Month</Option>
                    <Option value="lastmonth">Last Month</Option>
                  </Select>
                </Space>
              : ""}
              {lastDepDateCheckbox == true ?
                <div style={{...styles.inline, ...styles.dateRange}}>
                  Last Deposit Date Range
                </div>
              : ""}
              {lastWthDateCheckbox == true ?
                <br></br>
              : ""}
              {lastWthDateCheckbox == true ?
                <DatePicker
                  key="7"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker51}
                  value={trxDateFrom5}
                  ref={reportDateFrom5}
                  refs
                  placeholder={"Select Last Withdraw Date From"}
                  disabledDate={(current)=> {
                    return current > moment();//.subtract(1, 'day');
                  }}
                />
              : ""}
              {lastWthDateCheckbox == true ?
                <DatePicker
                  key="8"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker52}
                  value={trxDateTo5}
                  ref={reportDateTo5}
                  placeholder={"Select Last Withdraw Date To"}
                  disabledDate={(current)=> {
                    let dateFrom = trxDateFrom5;
                    if (dateFrom) {
                      return current < dateFrom || current > moment();//.subtract(1, 'day');
                    }else {
                      return  current > moment();//.subtract(1, 'day');
                    }
                  }}
                />
              : ""}
              {lastWthDateCheckbox == true ?
                <Space>
                  <Select style={{...styles.datePickerSelectionStyle}} value={type5} type={type5} onChange={setType5}>
                    <Option value="custom">Custom</Option>
                    <Option value="today">Today</Option>
                    <Option value="yesterday">Yesterday</Option>
                    <Option value="thisweek">This Week</Option>
                    <Option value="thismonth">This Month</Option>
                    <Option value="lastmonth">Last Month</Option>
                  </Select>
                </Space>
              : ""}
              {lastWthDateCheckbox == true ?
                <div style={{...styles.inline, ...styles.dateRange}}>
                  Last Withdraw Date Range
                </div>
              : ""}
              {lastActiveDateCheckbox == true ?
                <br></br>
              : ""}
              {lastActiveDateCheckbox == true ?
                <DatePicker
                  key="9"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker61}
                  value={trxDateFrom6}
                  ref={reportDateFrom6}
                  refs
                  placeholder={"Select Last Active Date From"}
                  disabledDate={(current)=> {
                    return current > moment();//.subtract(1, 'day');
                  }}
                />
              : ""}
              {lastActiveDateCheckbox == true ?
                <DatePicker
                  key="10"
                  style={{...styles.datePickerStyle, ...styles.refreshBtn}}
                  format="YYYY-MM-DD"
                  onChange={trxDatePicker62}
                  value={trxDateTo6}
                  ref={reportDateTo6}
                  placeholder={"Select Last Active Date To"}
                  disabledDate={(current)=> {
                    let dateFrom = trxDateFrom6;
                    if (dateFrom) {
                      return current < dateFrom || current > moment();//.subtract(1, 'day');
                    }else {
                      return  current > moment();//.subtract(1, 'day');
                    }
                  }}
                />
              : ""}
              {lastActiveDateCheckbox == true ?
                <Space>
                  <Select style={{...styles.datePickerSelectionStyle}} value={type6} type={type6} onChange={setType6}>
                    <Option value="custom">Custom</Option>
                    <Option value="today">Today</Option>
                    <Option value="yesterday">Yesterday</Option>
                    <Option value="thisweek">This Week</Option>
                    <Option value="thismonth">This Month</Option>
                    <Option value="lastmonth">Last Month</Option>
                  </Select>
                </Space>
              : ""}
              {lastActiveDateCheckbox == true ?
                <div style={{...styles.inline, ...styles.dateRange}}>
                  Last Active Date Range
                </div>
              : ""}
              {mobileFilterCheckbox == true ?
              <br></br>
              : ""}
              {mobileFilterCheckbox == true ?
                <Input
                  type="text"
                  id="searchMobileNo"
                  placeholder="Enter Mobile"
                  style={{...styles.mobileSelectionStyle}}
                  onChange={(e) => {
                      setSearchMobile(e.target.value.replace(/\D|^0/g, ""));                
                  }}
                  allowClear={true}
                  value={searchMobile}
                  ref={reportMobile}
                  >
                </Input>
              : ""}
              {mobileFilterCheckbox == true ?
                <div style={{...styles.inline, ...styles.dateRange}}>
                  Mobile
                </div>
              : ""}
              {depositStatusCheckbox == true ?
              <br></br>
              : ""}
              {depositStatusCheckbox == true ?
                <Space>
                  <Select placeholder="Enter Deposit Status" style={{...styles.depositStatusStyle}} value={type} type={type} onChange={setType}>
                    <Option value="selectDepositStatus">Select Deposit Status</Option>
                    <Option value="yes">Yes</Option>
                    <Option value="no">No</Option>
                  </Select>
                </Space>
              : ""}
              {depositStatusCheckbox == true ?
                <div style={{...styles.inline, ...styles.dateRange}}>
                  Deposit Status
                </div>
              : ""}
            </PageHeader>
            <div style={styles.tableContainer}>
              <Table
                loading={tableLoading} 
                columns={columns}
                dataSource={allSummaryReport}
                scroll={{ x: 100, y: null }}
                exportable={true} 
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: settings.pageSizeOptionsSmall,
                  style: styles.paginationStyle,
                }}
                exportableProps={{
                  // feedFields,
                  fileName: "PlayerReport_"+todayDate,
                  btnProps: {
                    type: "primary",
                    icon: <FileExcelOutlined />,
                    id:"export-button-1",
                    children: <span>Export to Excel</span>,
                    style: {display:"none"}
                  },
                }}
              />
              <br></br>
              <p><b>REMARK :</b></p>
              <p><b><span style={{color:"blue"}}>[1]</span></b> Player Report records until yesterday only</p>
              <p><b><span style={{color:"blue"}}>[2]</span></b> Register Date, First Deposit Date, Last Deposit Date and Last Withdraw Date are in GMT+8</p>
              <p><b><span style={{color:"blue"}}>[3]</span></b> <span style={{color:"#339966"}}>Bonus</span> and <span style={{color:"#339966"}}>Min/Max Deposit</span> is expandable</p>
              <p><b><span style={{color:"blue"}}>[4]</span></b> Tick the filter option to add filtering</p>
              <p><b><span style={{color:"blue"}}>[5]</span></b> Bracket <span style={{color:"blue"}}>&#x5B;<b> </b>&#93;</span> means frequency</p>
              <p><b><span style={{color:"blue"}}>[6]</span></b> The name was empty means the customer has not registered the bank yet</p>
              <p><b><span style={{color:"blue"}}>[7]</span></b> The first or last "deposit/withdraw" is based on the day only</p>
              <p><b><span style={{color:"blue"}}>[8]</span></b> Affiliate Comm only have amount on first day of next month</p>
            </div>
            <div style={{...styles.inlineFlex, ...styles.hide}}>
              <div><PickerWithType1 type={type1} onChange={(value) => console.log(value)} /></div>
              <div><PickerWithType2 type={type2} onChange={(value) => console.log(value)} /></div>
              <div><PickerWithType3 type={type3} onChange={(value) => console.log(value)} /></div>
              <div><PickerWithType4 type={type4} onChange={(value) => console.log(value)} /></div>
              <div><PickerWithType5 type={type5} onChange={(value) => console.log(value)} /></div>
              <div><PickerWithType6 type={type6} onChange={(value) => console.log(value)} /></div>
            </div>
          </div>
        )}
        </div>
        // :
        // <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
      }
    </div>
      
  );
}
import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Button,
  Modal,
  PageHeader,
  Breadcrumb,
  message,
  Badge,
  Select,
  InputNumber,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  RightOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  createSystemUserV2,
  createSystemUserV3,
  userAgentList,
  userAgentListV2,
  userAgentListV3,
  userManageUpdate,
  userManageUpdateV2,
  verifyValidMobile,
  getCompanyProfileData,
  getUserData,
  getUserDataV2,
  checkUsernameRegisteredV2,
  getPageAccess,
  setPageAccess,
  reportShareholderList,
} from "../../config/networkConfig";
import md5 from "md5";
import { useSelector } from "react-redux";
import { isMobile, isIPad13 } from "react-device-detect";
import { Link } from "react-router-dom";
import { USER_TYPE_AGENT } from "../../common/constants/constant";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { agent } from "../../config/route";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = dataIndex == 'nickname' ? <Input allowClear={true} /> 
                  : dataIndex == 'mobilenumber' ? <InputNumber maxLength={15} style={{width:"170px"}}/>
                  : dataIndex == 'pin' ? <InputNumber maxLength={6} style={{width:"100px"}}/>
                  :<></>;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `${title} cannot be empty !`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function Withdraw() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const modalForm = useRef();
  const [originData, setOriginData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [username1, setUsername] = useState("");
  const [nickname, setNickname] = useState("");
  const [agentType, setAgentType] = useState("");
  const [password, setPassword] = useState("");
  const md5Pass = md5(password);
  const [searchText, setSearchText] = useState("");
  const [addVisible, setAddVisible] = useState(false);
  const { userId, compCode, role, companyId, username } = useSelector((state) => state.login);
  const [cardVisible, setCardVisible] = useState(false);
  const [activeKey, setActiveKey] = useState("");
  const [loadings, setLoadings] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("856");
  const [mobilenumber, setMobileNumber] = useState("");
  const [lineId, setLineId] = useState("");
  const [createMemberDisable, setCreateMemberDisable] = useState(true);
  const [cancelDisable, setCancelDisable] = useState(false);
  const [pin,setPin] = useState("");
  const [companyCountryCode, setCompanyCountryCode] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [createButtonLoading, setCreateButtonLoading] = useState(false);
  const isLandscape = window.orientation === 90 || window.orientation === -90;

  const modalEditForm = useRef();
  const [editVisible, setEditVisible] = useState(false);
  const [editUserID, setEditUserID] = useState("");
  const [editNickname, setEditNickname] = useState("");
  const [editCountryCode, setEditCountryCode] = useState("");
  const [editMobileNumber, setEditMobileNumber] = useState("");
  const [editLineId, setEditLineId] = useState("");
  const [editPassword, setEditPassword] = useState("");
  const [editPin, setEditPin] = useState("");
  const [editAgentType, setEditAgentType] = useState("");
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const [shareholderSelection, setShareholderSelection] = useState(null);
  const reportShareholderRef = useRef(null);
  const [shareholderOptions, setShareholderOptions] = useState([]);
  const [shareholderSelectionSearch, setShareholderSelectionSearch] = useState(null);
  const shareholderRefSearch = useRef(null);
  const [shareholderOptionsSearch, setShareholderOptionsSearch] = useState([]);
  const [isAdminCS, setIsAdminCS] = useState(false);
  const [status, setStatus] = useState("All");

  useEffect(() => {
    shareholderList();
  }, [status]);

  useEffect(() => {
    if (role === "Admin_CS") {
      setIsAdminCS(true);
    } else {
      setIsAdminCS(false);
    }
  }, [role]);

  useEffect(()=> {
    setShareholderSelection(shareholderSelection);
  },[shareholderSelection]);

  useEffect(()=> {
    setShareholderSelectionSearch(shareholderSelectionSearch);
  },[shareholderSelectionSearch]);

  useEffect(() => {
    if (window.DeviceOrientationEvent) {
      window.addEventListener(
        "orientationchange",
        function () {
          window.location.reload();
        },
        false
      );
    }
    loadCompanyProfileData();
    downlineList();
    // eslint-disable-next-line
  }, []);

  const loadCompanyProfileData = async() => { 
    // Step 1: Get company id from user profile based on userId
    let requestData = {
      userID : userId,
      columnRequire : ['uuidfrombin(company) as companyid'],
      role : role,
    };

    const response = await getUserDataV2(requestData)
    let result = await response.json();
    
    if (result.status === "ok") {
      let requestData = { 
        companyID: result.data[0].companyid,
        columnRequire: ['MobileCode']
      }

      // Step 2: Get company profile based on company id from step 1
      const response2 = await getCompanyProfileData(requestData);
      let result2 = await response2.json();
      if (result2.status === "ok") {
        setCompanyCountryCode(result2.data[0].MobileCode);
      }
      else {
        alertData.title = "Failed";
        alertData.msg = "Connection not stable, please try again !";
        sharedFailAlert(alertData);
      }
    }
    else {
      alertData.title = "Failed";
      alertData.msg = "Connection not stable, please try again !";
      sharedFailAlert(alertData);
    }
  }

  const shareholderList = async () => {
    try{
        let requestData = {
            role : role,
            compCode : compCode,
            userId : userId
        }

        const response = await reportShareholderList(requestData);
        let result = await response.json();
        
        if (result.status === 'ok') {
            setShareholderOptions(result.msg != 'No record.' ? result.data : []);
            setShareholderOptionsSearch(result.msg != 'No record.' ? result.data : []);
        }
        else{
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }  
    }
    catch (error) {
        // console.log('Error fetching agent list:', error);
        alertData.title = "Error";
        alertData.msg = "Failed to fetch shareholder list";
        sharedFailAlert(alertData);
    }
  }

  const downlineList = async() => {
    var count = 0;
    let response = '';
    setTableLoading(true);
    
    response = await userAgentListV3({ userId: userId, role: role });
    let result = await response.json();

    if (result.status == "ok") {
      setTableLoading(false);

      const newData = Object.keys(result.data).length
        ? result.data.map((x) => {
            return {
              key: count++,
              userid: x.userId,
              username1: x.username,
              pin: x.pin,
              nickname: x.nickname,
              mobilenumber: x.mobilenumber,
              lineid: x.lineID,
              lastLogin: x.lastLogin,
              registerDate: x.registerDate,
              type: x.memberCount,
              usertype: x.type,
              userTitle: x.userTitle,
              referral_code : x.referralCode,
              status:
                x.status === "0"
                  ? "Lock"
                  : x.status === "1"
                  ? "Active"
                  : "Inactive",
            };
          })
        : [];
        
      setOriginData(newData);
    }
    else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      setTableLoading(false);
      return;
    }

    // userAgentList({ userId: userId })
    //   .then((response) => response.json())
    //   .then((json) => {
    //     if (json.status === "ok") {
    //       setTableLoading(false);
    //       setAddVisible(false);
    //       const newData = Object.keys(json.data).length
    //         ? json.data.map((x) => {
    //             return {
    //               key: count++,
    //               userid: x.userId,
    //               username: x.username,
    //               pin: x.pin,
    //               nickname: x.nickname,
    //               mobilenumber: x.mobilenumber,
    //               lastLogin: x.lastLogin,
    //               registerDate: x.registerDate,
    //               type: x.memberCount,
    //               usertype: x.type,
    //               userTitle: x.userTitle,
    //               status:
    //                 x.status === "0"
    //                   ? "Lock"
    //                   : x.status === "1"
    //                   ? "Active"
    //                   : "Inactive",
    //             };
    //           })
    //         : [];
    //       setOriginData(newData);
    //     } 
    //     else {
    //       alertData.title = "Failed";
    //       alertData.msg = json.msg;
    //       sharedFailAlert(alertData);
    //       setTableLoading(true);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log("error: ", error);
    //     alertData.title = "Error";
    //     alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
    //     sharedErrorAlert(alertData);
    //   });
  };

  const searchDownlineList = async() => {
    var count = 0;
    let response = '';
    setTableLoading(true);
    
    response = await userAgentListV3({ userId: shareholderSelectionSearch, role: 'Shareholder' });
    let result = await response.json();

    if (result.status == "ok") {
      setTableLoading(false);

      const newData = Object.keys(result.data).length
        ? result.data.map((x) => {
            return {
              key: count++,
              userid: x.userId,
              username1: x.username,
              pin: x.pin,
              nickname: x.nickname,
              mobilenumber: x.mobilenumber,
              lineid: x.lineID,
              lastLogin: x.lastLogin,
              registerDate: x.registerDate,
              type: x.memberCount,
              usertype: x.type,
              userTitle: x.userTitle,
              referral_code : x.referralCode,
              status:
                x.status === "0"
                  ? "Lock"
                  : x.status === "1"
                  ? "Active"
                  : "Inactive",
            };
          })
        : [];
        
      setOriginData(newData);
    }
    else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
      setTableLoading(false);
      return;
    }
  };

  const showModal = () => {
    setEditingKey("");
    setAddVisible(true);
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },

    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const columns = [
    {
      align: "center",
      title: "Username",
      dataIndex: "username1",
      width: "200px",
      // ...getColumnSearchProps("username"),
    },
    {
      align: "center",
      title: "Nickname",
      dataIndex: "nickname",
      width: "200px",
      editable: true,
      
      // ...getColumnSearchProps("nickname"),
    },
    // {
    //   ellipsis: true,
    //   align: "center",
    //   title: "Mobile Number",
    //   dataIndex: "mobilenumber",
    //   width: "230px",
    //   editable: true,
    //   ...getColumnSearchProps("mobilenumber"),
    // },
    {
      width: "220px",
      ellipsis: true,
      align: "center",
      title: "Line ID",
      dataIndex: "lineid",
      editable: true,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
  },
    // {
    //   ellipsis: true,
    //   align: "center",
    //   title: "PIN",
    //   dataIndex: "pin",
    //   editable: true,
    //   width: "150px",
    // },
    {
      ellipsis: true,
      align: "center",
      title: "Affiliate Code",
      dataIndex: "referral_code",
      width: "150px",
    },
    {
      align: "center",
      title: "Agent Type",
      dataIndex: "userTitle",
      width: "150px",
    },
    {
      align: "center",
      title: "Creation Date",
      dataIndex: "registerDate",
      width: "200px",
    },
    {
      align: "center",
      title: "No. of MB",
      dataIndex: "type",
      width: "150px",
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "status",
      width: "100px",
    },
    {
      width: "100px",
      align: "center",
      title: "Action",
      dataIndex: "operation",
      render: (text, record) => {
        return (
            <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                    showEditModal(record);
                }}
            />
        )
      },
      // render: (text, record) => {
      //   const editable = isEditing(record);
      //   return editable ? (
      //     <div
      //       style={{
      //         flexDirection: "row",
      //         display: "flex",
      //         justifyContent: "space-evenly",
      //       }}
      //     >
      //       <Input
      //         id={"pwd" + record.key}
      //         placeholder="Change Password"
      //         style={{ width: "auto", textAlign: "center" }}
      //         onChange={(e) => {
      //           setPassword(e.target.value);
      //         }}
      //       />
      //       <Button
      //         type="dashed"
      //         shape="circle"
      //         icon={<SaveOutlined />}
      //         loading={loadings[1]}
      //         onClick={() => {
      //           save(record, 1);
      //         }}
      //       />
      //       <div>
      //         <Popconfirm
      //           placement="topRight"
      //           title="Confirm cancel?"
      //           onConfirm={() => cancel(record.key)}
      //         >
      //           <Button
      //             type="danger"
      //             shape="circle"
      //             icon={<CloseCircleOutlined />}
      //           />
      //         </Popconfirm>
      //       </div>
      //     </div>
      //   ) : (
      //     <span disabled={editingKey !== ""} onClick={() => edit(record)}>
      //       <Button type="primary" shape="circle" icon={<EditOutlined />} />
      //     </span>
      //   );
      // },
    },
  ].filter(item => !item.hidden);

  const isEditing = (record) => record.key === editingKey;

  const edit = async (record) => {
    form.setFieldsValue({
      nickname: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const showEditModal = async (record) => {
    setMobileNumber(record.mobilenumber);
    setNickname(record.nickname);
    setLineId(record.lineid);
    setPin(record.pin);
    setAgentType(record.userTitle);

    let countryCodeLength = String(companyCountryCode).length;
    setEditUserID(record.userid);
    // setEditCountryCode("+"+companyCountryCode);
    setEditCountryCode("+"+record.mobilenumber.slice(0,countryCodeLength));
    setEditMobileNumber((record.mobilenumber).slice(countryCodeLength));
    setEditLineId(record.lineid);
    setEditNickname(record.nickname);
    setEditPin(record.pin);
    setEditAgentType(record.userTitle);
    setEditVisible(true);
  }

  const handleEdit = async (record, index) => {
    setTableLoading(true);
    setEditButtonLoading(true);

    let requestData = {
      userid : editUserID,
      usertype : USER_TYPE_AGENT,
    };

    let tmpMobile = editCountryCode.slice(1) + editMobileNumber;

    if (tmpMobile != mobilenumber && String(editMobileNumber).length < 9) {
      alertData.title = "Format Error";
      alertData.msg = "New mobile number is too short !";
      sharedErrorAlert(alertData);
      setTableLoading(false);
      setEditButtonLoading(false);
      return;
    }
    
    if (tmpMobile != mobilenumber && String(editMobileNumber).length >= 9) {
      // Verify if number is used by all type of user
      const verifyNumber = await validateUserPhoneV2(String(tmpMobile));
      if (verifyNumber == true) {
          requestData.mobile = String(tmpMobile);
      }
      else{
          alertData.title = "Validation Error";
          alertData.msg = `+${tmpMobile} is used by another user !`;
          sharedFailAlert(alertData);
          setTableLoading(false);
          setEditButtonLoading(false);
          return;
      }
    }

    if (editNickname != '' && editNickname != nickname) {
      requestData.nickname = editNickname;
    }

    if (editLineId != lineId) {
      requestData.lineId = editLineId;
    }

    if (editPin != '' && String(editPin).length < 6) {
      alertData.title = "Format Error";
      alertData.msg = "PIN must not less than 6 digit !";
      sharedErrorAlert(alertData);
      setTableLoading(false);
      setEditButtonLoading(false);
      return;
    }

    // if (editAgentType != agentType)
    // {
    //   requestData.agentType = editAgentType;
    // }

    if (editPin != pin) {
      requestData.pin = editPin;
    }

    if (editPassword != '') {
      requestData.pass = md5(editPassword);
    }

  userManageUpdateV2(requestData)
    .then((response) => response.json())
    .then((json) => {
      if (json.status === "ok") {
        setTableLoading(false);
        setEditButtonLoading(false);
        alertData.title = "Success";
        alertData.msg = json.msg;
        sharedSuccessAlert(alertData);
        setTableLoading(false);
        setEditButtonLoading(false);
        onCancelEdit();
        downlineList();
        
      } 
      else {
        setTableLoading(false);
        setEditButtonLoading(false);
        alertData.title = "Failed";
        alertData.msg = json.msg;
        sharedFailAlert(alertData);
        setTableLoading(false);
        setEditButtonLoading(false);
      }
    })
    .catch((error) => {
      console.log("error: ", error);
      alertData.title = "Error";
      alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
      sharedErrorAlert(alertData);
      setTableLoading(false);
      setEditButtonLoading(false);
    });
  }

  const onCancelEdit = () => {
    setUsername("");
    setNickname("");
    setMobileNumber("");
    setLineId("");
    setPin("");
    setPassword("");
    setAgentType("");

    setEditUserID("");
    setEditNickname("");
    setEditMobileNumber("");
    setEditLineId("");
    setEditPin("");
    setEditPassword("");
    setEditAgentType("");
    modalEditForm.current.resetFields();
    setEditVisible(false);
  };

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const save = async (record, index) => {
    const row = await form.validateFields();
    const { nickname, mobilenumber, pin } = row;
    let requestData = {
      userid : record.userid
    };

    setTableLoading(true);
    
    if (nickname != record.nickname) {
      requestData.nickname = nickname;
    }

    if (mobilenumber != record.mobilenumber && String(mobilenumber).length < 10) {
      setTableLoading(false);
      alertData.title = "Format Error";
      alertData.msg = "New mobile number is too short !";
      sharedErrorAlert(alertData);
      return;
    }
    
    let tmpMobile = '';

    if (mobilenumber != record.mobilenumber && String(mobilenumber).length >= 10) {
      let substringLength = String(companyCountryCode).length;

      if (String(mobilenumber).substring(0,substringLength) != companyCountryCode) {
        tmpMobile = `${companyCountryCode}` + mobilenumber;
        // if(companyCountryCode == '60') {
          
        //   if(mobilenumber.substring(0,1) == '0') {
        //     requestData.mobile = `${String(companyCountryCode).substring(0,1)}` + mobilenumber;
        //   }
        //   else {
        //     requestData.mobile = `${companyCountryCode}` + mobilenumber;
        //   }
        // }
        // else{
        //   requestData.mobile = `${companyCountryCode}` + mobilenumber;
        // }
      }
      else {
        tmpMobile = mobilenumber;
      }
      // Verify if number is used by all type of user
      const verifyNumber = await validateUserPhoneV2(String(tmpMobile));
      if (verifyNumber == true) {
        requestData.mobile = String(tmpMobile);
      }
      else{
        setTableLoading(false);
        alertData.title = "Validation Error";
        alertData.msg = `+${tmpMobile} is used by another user !`;
        sharedFailAlert(alertData);
        return;
      }
    }

    if (String(pin).length < 6) {
      setTableLoading(false);
      alertData.title = "Format Error";
      alertData.msg = "PIN must not less than 6 digit !";
      sharedErrorAlert(alertData);
      return;
    }

    if (pin != record.pin) {
      if(Number.isInteger(pin) == true) {
        requestData.pin = pin;
      }
      else{
        setTableLoading(false);
        alertData.title = "Format Error";
        alertData.msg = "PIN must contain only digit / number !";
        sharedErrorAlert(alertData);
        return;
      }
    }

    if (document.getElementById("pwd" + record.key).value != '') {
      requestData.pass = md5Pass;
    }

    if (document.getElementById("pwd" + record.key).value != '') {
      requestData.pass = md5Pass;
    }

    if (nickname === record.nickname && mobilenumber === record.mobilenumber && pin === record.pin && document.getElementById("pwd" + record.key).value === "") {
      setEditingKey("");
      setTableLoading(false);
      return;
    }

    try {
      // const newData = [...originData];
      // const indexSave = newData.findIndex((item) => record.key === item.key);
      // loadingTrue(index);
      // if (indexSave > -1) {
        if (requestData != {}) {
          userManageUpdateV2(requestData)
            .then((response) => response.json())
            .then((json) => {
              if (json.status === "ok") {
                alertData.title = "Success";
                alertData.msg = json.msg;
                sharedSuccessAlert(alertData);
                loadingFalse(index);
                // const item = newData[indexSave];
                // newData.splice(indexSave, 1, { ...item, ...row });
                setEditingKey("");
                downlineList();
              }
              else {
                alertData.title = "Failed";
                alertData.msg = json.msg;
                sharedFailAlert(alertData);
                loadingFalse(index);
                setTableLoading(false);
              }
            })
            .catch((error) => {
              console.log("error: ", error);
              alertData.title = "Error";
              alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
              sharedErrorAlert(alertData);
            });
          }
          else {
            alertData.title = "Error";
            alertData.msg = "Connection not stable, please try again !";
            sharedErrorAlert(alertData);  
        }
    } 
    catch (errInfo) {
      console.log("errInfo: ", errInfo);
      alertData.title = "Error";
      alertData.msg = "Connection not stable, please try again !";
      sharedErrorAlert(alertData);
    }
  };

  const handleAdd = (index) => {
    var regEx = new RegExp(/^\d*[a-z]{1,}\d*/);
    setCreateButtonLoading(true);

    // if (!mobilenumber) {
    //   alertData.title = "Field Required";
    //   alertData.msg = "Please fill in Mobile No !";
    //   sharedErrorAlert(alertData);
    //   setCreateButtonLoading(false);
    //   return;
    // }

    // if (mobilenumber.length < 9 ) {
    //   alertData.title = "Invalid Mobile No";
    //   alertData.msg = "Mobile number is too short !";
    //   sharedErrorAlert(alertData);
    //   setCreateButtonLoading(false);
    //   return;
    // }

    // if (!pin) {
    //   alertData.title = "Field Required";
    //   alertData.msg = "Please fill in 6 Digit Pin !";
    //   sharedErrorAlert(alertData);
    //   setCreateButtonLoading(false);
    //   return;
    // }
    // else{
    //   if (pin.length < 6) {
    //     alertData.title = "Invalid Pin";
    //     alertData.msg = "PIN at least 6 characters !";
    //     sharedErrorAlert(alertData);
    //     setCreateButtonLoading(false);
    //     return;
    //   }
    // }

    // if (!username1 || !nickname || !password) {
    //   alertData.title = "Field Required";
    //   alertData.msg = "Please fill in Username, Nickname, Password !";
    //   sharedErrorAlert(alertData);
    //   setCreateButtonLoading(false);
    //   return;
    // }
    // else if (!regEx.test(username1)) {
    //   alertData.title = "Format Error";
    //   alertData.msg = "Username only accept lowercase characters and numbers !";
    //   sharedErrorAlert(alertData);
    //   setCreateButtonLoading(false);
    //   return;
    // }

    if (role === 'Admin_CS' && !shareholderSelection) {
      alertData.title = "Field Required";
      alertData.msg = "Please select a shareholder !";
      sharedErrorAlert(alertData);
      setCreateButtonLoading(false);
      return;
    }

    if (referralCode && referralCode.length < 6 ) {
      alertData.title = "Invalid Referral Code";
      alertData.msg = "Referral code requires at least 6 characters !";
      sharedErrorAlert(alertData);
      setCreateButtonLoading(false);
      return;
    }

    if (agentType == '' ) {
      alertData.title = "Field Required";
      alertData.msg = "Please select Agent Type !";
      sharedErrorAlert(alertData);
      setCreateButtonLoading(false);
      return;
    }
    // if (pin.length < 6  || pin.length > 6 ) {
    //   alertData.title = "Invalid Pin";
    //   alertData.msg = "PIN must be 6 characters !";
    //   sharedErrorAlert(alertData);
    //   return;
    // }
    // if (mobilenumber.length < 7 ) {
    //   alertData.title = "Invalid Mobile No";
    //   alertData.msg = "Mobile number is too short !";
    //   sharedErrorAlert(alertData);
    //   return;
    // }

    loadingTrue(index);

    let requestData = {
      userId: userId,
      userType : USER_TYPE_AGENT,
      // username : username1.toLowerCase().trim(),
      // nickname : nickname,
      mobilenumber : mobilenumber != '' ? countryCode+mobilenumber : 'xxxxxxxxxx',
      lineId : lineId,
      pin : pin != '' ? pin : '111111',
      // pass : md5Pass,
      referralCode : referralCode,
      agentType: agentType,
      companyId: companyId,
      upline: role === 'Admin_CS' ? shareholderSelection : userId,
      role: role,
    }

    createSystemUserV3(requestData)
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          loadingFalse(index);
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
          giveAccess(json.data);
          downlineList();
          setCreateButtonLoading(false);
          onCancel();
        }
        else {
          loadingTrue(index);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
          setCreateButtonLoading(false);
        }
        loadingFalse(index);
        // modalForm.current.resetFields();
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
        setCreateButtonLoading(false);
      });
  };

  const giveAccess = async (data) => {
    const userList = data;
    // Retrieve User Data

    // Retrieve Access Data
    for (const user of userList) {
      const { userId, userType } = user;

      // Fetch page access template based on userType
      const pageAccessResponse = await getPageAccess({ accessName: userType });
      const pageAccessResult = await pageAccessResponse.json();

      if (pageAccessResult.status === 'ok') {
        // Format data
        const pageAccessTemplate = pageAccessResult.data.map((obj) => {
          return {
            pageId: obj.PageId,
            status: obj.AccessStatus,
            read: obj.ReadAccess,
            write: obj.WriteAccess,
            create: obj.CreateAccess,
            delete: obj.DeleteAccess,
          }
        })

        const setPageAccessResponse = await setPageAccess({
            userId,
            pageAccessData: pageAccessTemplate
        });
        const setPageAccessResult = await setPageAccessResponse.json()

        if (setPageAccessResult.status !== 'ok') {
          break; // Exit the loop on error
        }
      }
    }
  }

  const onChangeCountryCode = (value) => {
    setCountryCode(value);
  };

  const onChangeCountryCodeV2 = (value) => {
    setEditCountryCode(value);
  };

  const validateUserPhone = async(data)=> {
    let tmpMobileNo = '', tmpCountryCode = '', requireVerify = true;
    if (data.action == 'add') {
      tmpMobileNo = countryCode + data.mobileNumber ;
    }

    if (data.action == 'edit') {
      if (editCountryCode.includes('+') == true) {
        tmpCountryCode = editCountryCode.slice(1);
      }
      else {
        tmpCountryCode = editCountryCode;
      }
      tmpMobileNo = tmpCountryCode + data.mobileNumber;

      if (tmpMobileNo == mobilenumber) {
        requireVerify = false;
      }
    }

    if (requireVerify == true) {
      const response = await verifyValidMobile({mobile: tmpMobileNo});
      let result = await response.json();
      if (result.status == "ok") {
        alertData.title = "Failed";
        alertData.msg = `+${tmpMobileNo} is used by another user !`;
        sharedFailAlert(alertData);
      }
    }
  }

  const validateUserPhoneV2 = async(mobilenumber)=> {
    const response = await verifyValidMobile({mobile: mobilenumber});
    let result = await response.json();

    if (result.status === "ok") {
      return false;
    }
    else{
      return true;
    }
  }

  const validateUsername = async(username)=> {
    if (username != undefined || username != '') {

        let requestData = {
            username: username
        }

        const response = await checkUsernameRegisteredV2(requestData);
        let result = await response.json();
        if (result.status === "ok") {}
        else{
            alertData.title = "Validation Error";
            alertData.msg = result.msg;
            sharedErrorAlert(alertData);
        }
    }
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onCancel = () => {
    setUsername("");
    setNickname("");
    setMobileNumber("");
    setLineId("");
    setReferralCode("");
    setAgentType("");
    setPin("");
    setPassword("");
    modalForm.current.resetFields();
    setAddVisible(false);
  };

  const cardStyle = () => {
    const newData = [...originData];

    const statusBgColor = Object.values(newData).map((data) => {
      return data.status === "Active"
        ? "linear-gradient(45deg,#FFFFFF, #1DA57A)"
        : data.status === "Inactive"
        ? "linear-gradient(45deg,#FFFFFF, #FF0000)"
        : "linear-gradient(45deg,#FFFFFF, #FFD700)";
    });

    const cardList = Object.values(newData).map((data) => {
      return (
        <Button
          key={data.key}
          type="primary"
          className="cardStyle"
          style={{
            backgroundImage: statusBgColor,
            color: "#000000",
            marginBottom: 30,
          }}
          onClick={() => {
            setCardVisible(true);
            setActiveKey(data.key);
          }}
        >
          <div>{data.status}</div>
          <div>
            <div>{data.username}</div>
            <div>No. of MB: {data.type}</div>
          </div>
          <div>
            <RightOutlined />
          </div>
        </Button>
      );
    });

    const getUserId = Object.values(newData).map((data) => data.userid);

    const cancel = () => {
      var usernameStyle = document.getElementById("username");
      var passStyle = document.getElementById("password");
      var edit = document.getElementById("edit");
      setCardVisible(false);
      usernameStyle.style.border = "none";
      usernameStyle.style.padding = "0px";
      passStyle.style.border = "none";
      passStyle.style.padding = "0px";
      passStyle.innerHTML = "";
      edit.innerHTML = "Edit";
    };

    // const save = (index) => {
    //   var usernameStyle = document.getElementById("username");
    //   var passStyle = document.getElementById("password");
    //   var edit = document.getElementById("edit");
    //   loadingTrue(index);
    //   userManageUpdate({
    //     userId: getUserId,
    //     nickname: nickname,
    //     pass: md5Pass,
    //   })
    //     .then((response) => response.json())
    //     .then((json) => {
    //       if (json.status === "ok") {
    //         message.success(json.msg);
    //         setCardVisible(false);
    //         loadingFalse(index);
    //         usernameStyle.style.border = "none";
    //         usernameStyle.style.padding = "0px";
    //         passStyle.style.border = "none";
    //         passStyle.style.padding = "0px";
    //         passStyle.innerHTML = "";
    //         edit.innerHTML = "Edit";
    //       } else {
    //         message.error(json.msg);
    //         loadingTrue(index);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log("error: ", error);
    //       message.error(
    //         "An unexpected error occurred. Contact your system administrator "
    //       );
    //     });
    //   loadingFalse(index);
    // };

    // const edit = () => {
    //   var edit = document.getElementById("edit");
    //   var usernameStyle = document.getElementById("username");
    //   var passStyle = document.getElementById("password");

    //   edit.innerHTML = "Save";
    //   usernameStyle.contentEditable = true;
    //   usernameStyle.style.border = "1px solid gray";
    //   usernameStyle.style.paddingLeft = "10px";
    //   usernameStyle.style.paddingRight = "10px";
    //   usernameStyle.addEventListener(
    //     "input",
    //     function (e) {
    //       setNickname(e.currentTarget.textContent);
    //     },
    //     false
    //   );

    //   passStyle.contentEditable = true;
    //   passStyle.style.border = "1px solid gray";
    //   passStyle.style.width = "100px";
    //   passStyle.style.paddingLeft = "10px";
    //   passStyle.style.paddingRight = "10px";
    //   passStyle.addEventListener(
    //     "input",
    //     function (e) {
    //       setPassword(e.currentTarget.textContent);
    //     },
    //     false
    //   );
    // };

    const ok = () => {
      var x = document.getElementById("edit");
      if (x.innerHTML === "Edit") {
        edit();
      } else if (x.innerHTML === "Save") {
        save(2);
      }
    };

    const details = () => {
      var newData = [...originData];
      const status = Object.values(newData).map((data) => {
        return data.status === "Active" ? (
          <Badge key="1" status="success" text="Active" />
        ) : data.status === "Inactive" ? (
          <Badge key="2" status="error" text="Inactive" />
        ) : (
          <Badge key="3" status="warning" text="Lock" />
        );
      });

      const username = Object.values(newData).map((data) => data.username);
      const type = Object.values(newData).map((data) => data.type);
      const nickname = Object.values(newData).map((data) => data.nickname);
      const mobilenumber = Object.values(newData).map((data) => data.mobilenumber);
      const registerDate = Object.values(newData).map(
        (data) => data.registerDate
      );

      return (
        <div style={styles.decContainer}>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Status:</p>
            <p>{status[activeKey]}</p>
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Username:</p>
            <p>{username[activeKey]}</p>
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Nickname:</p>
            <p id="username" style={{ border: "none" }} type="text">
              {nickname[activeKey]}
            </p>
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Mobile Number:</p>
            <p>{mobilenumber[activeKey]}</p>
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Password:</p>
            <p type="text" id="password" style={{ border: "none" }} />
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>No. of MB:</p>
            <p>{type[activeKey]}</p>
          </div>
          <div style={styles.decStyle}>
            <p style={styles.decLable}>Registered Date:</p>
            <p>{registerDate[activeKey]}</p>
          </div>
          <div style={styles.modalBottomBtn}>
            <Button
              loading={loadings[2]}
              type="primary"
              style={styles.okBtn}
              onClick={() => {
                ok(2);
              }}
            >
              <span id="edit">Edit</span>
            </Button>
            <Button
              type="primary"
              style={{ width: 80 }}
              onClick={() => {
                cancel();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    };
    return (
      <div>
        <Modal
          key={activeKey}
          title="Detail"
          visible={cardVisible}
          footer={null}
          maskClosable={false}
        >
          {details()}
        </Modal>
        {cardList}
      </div>
    );
  };

  const routes = [
    {
      path: "/",
      breadcrumbName: "Dashboard",
    },
    {
      path: "agentManagement",
      breadcrumbName: "Agent Management",
    },
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join("/")}>{route.breadcrumbName}</Link>
    );
  };

  const searchBar = () => {
    if (role === 'Admin_CS' && !shareholderSelectionSearch) {
        shareholderRefSearch.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select a shareholder !";
        sharedErrorAlert(alertData);
        return;
    }
    setTableLoading(true);
    searchDownlineList();
  }

  const resetBtn = () => {
    setShareholderSelectionSearch(null);
    loadCompanyProfileData();
    downlineList();
  };

  const portrait = () => {
    return (
      <div>
        <PageHeader>
          <Breadcrumb separator=">" itemRender={itemRender} routes={routes} />
        </PageHeader>
        <PageHeader
          style={styles.PageHeaderStyle}
          ghost={false}
          extra={[
            <div key="1" style={styles.extraStyle}>
              <Button
                type="primary"
                onClick={showModal}
                style={styles.mobileAddBtn}
              >
                Add
              </Button>
            </div>,
            <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px", // Space between elements
              }}
            >
              {isAdminCS && (
                <Select
                value={shareholderSelectionSearch}
                optionFilterProp="children"
                ref={shareholderRefSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Please select a shareholder"
                style={{width: 230}}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => {
                  setShareholderSelectionSearch(value);
                }}
                >
                {shareholderOptionsSearch.map((option) => {
                  return (
                    <Option key={option.key} value={option.shareholder}>
                      {option.username}
                    </Option>
                  );
                })}
              </Select>
              )}
              {isAdminCS && (
                <Button
                  type={"primary"}
                  icon={<SearchOutlined
                  onClick={searchBar}
                  />}
                />
              )}
              {isAdminCS && (
                <Button style={{...styles.resetBtn,...styles.refreshBtn}} onClick={resetBtn}>
                  Reset
                </Button>
              )}
          </div>,
          ]}
          >
          {cardStyle()}
        </PageHeader>
      </div>
    );
  };

  const landscape = () => {
    return (
      <div>
        <PageHeader
          // style={{ marginBottom: 50 }}
          ghost={false}
          title="Agent Management"
        >
          <div
            style={{
              justifyContent: "left",
              display: "flex",
              alignItems: "center",
            }}
          >
            {isAdminCS && (
              <Select
              showSearch
              value={shareholderSelectionSearch}
              optionFilterProp="children"
              ref={shareholderRefSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              placeholder="Please select a shareholder"
              style={{width: 230}}
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => {
                setShareholderSelectionSearch(value);
              }}
              >
                {shareholderOptionsSearch.map((option) => {
                  return (
                    <Option key={option.key} value={option.shareholder}>
                      {option.username}
                    </Option>
                  );
                })}
              </Select>
            )}
            {isAdminCS && (
              <Button
                type={"primary"}
                icon={<SearchOutlined
                onClick={searchBar}
                />}
              />
            )}
            {isAdminCS && (
              <Button style={{...styles.resetBtn,...styles.refreshBtn, marginRight: 20}} onClick={resetBtn}>
                Reset
              </Button>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px", // Space between elements
              }}
            >
            </div>
            <Button type="primary" onClick={() => showModal()}>
              Add
            </Button>
          </div>
        </PageHeader> 
        <div style={styles.tableContainer}>
          <Form form={form} component={false}>
            <Table
              loading={tableLoading}
              scroll={{ x: "100%", y: null }}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              dataSource={originData}
              columns={mergedColumns}
              rowClassName="editable-row"
              pagination={{
                onChange: cancel,
              }}
              style={{ overflow: "auto" }}
            />
          </Form>
        </div>
      </div>
    );
  };

  const styles = {
    mobileAddBtn: {
      borderRadius: 10,
      width: 1200,
    },
    extraStyle: {
      alignItems: "center",
      display: "flex",
      height: 50,
      marginLeft: 15,
      marginRight: 15,
      justifyContent: "center",
      width: "100vw",
      padding: 10,
    },
    PageHeaderStyle: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
    },
    decStyle: {
      display: "flex",
      flexDirection: "row",
    },
    decLable: {
      width: 140,
    },
    decContainer: {
      paddingLeft: 75,
    },
    okBtn: {
      marginRight: 10,
      borderColor: "#000000",
      backgroundColor: "#FFFFFF",
      color: "#000000",
      width: 80,
    },
    modalBottomBtn: {
      display: "flex",
      flexDirection: "row",
      height: 50,
      alignItems: "flex-end",
      justifyContent: "flex-end",
    },
  };

  return (
    <div>
      <Modal title="New Agent" 
        visible={addVisible} 
        footer={null} 
        closable={false}
        >
          <Form 
            ref={modalForm}
            layout="vertical"
          >
            {/* <Form.Item
              name={["user", "mobilenumber"]}
              label="Mobile No"
              required={true}
            >
              <Input.Group compact>
                <Select 
                  style={{width:"21%"}} 
                  value={countryCode}
                  onChange={onChangeCountryCode}>
                    <Option value="856">+856</Option>
                    <Option value="66">+66</Option>
                    <Option value="60">+60</Option>
                </Select>
                <Input style={{width:"79%"}} 
                  placeholder="phone number" 
                  value={mobilenumber}
                  onChange={(e) => {
                    setMobileNumber(e.target.value.replace(/\D|^0/g, ""));
                  }}
                  onBlur={(e)=>{
                    let requestData = {
                      action : 'add',
                      mobileNumber: e.target.value.replace(/\D|^0/g, "")
                    }
                    validateUserPhone(requestData)
                  }}
                />
              </Input.Group>
            </Form.Item>

            <Form.Item
              name={["user", "pin"]}
              label="Pin"
              required
            >      
                <Input.Group compact>
                  <Input
                    value={pin}
                    placeholder="6 Digit Pin"
                    maxLength={40}
                    onChange={(e) => {
                      const newValue = e.target.value.toLowerCase().replace(/\s/g, ''); // Convert to lowercase and remove whitespace
                      // Regular expression to match only lowercase letters and numbers
                      const isValid = /^[a-z0-9]*$/.test(newValue);
                      if (isValid) {
                        setPin(newValue);
                      }
                    }}
                  />
                </Input.Group>       
            </Form.Item> */}

            {/* <Form.Item
              name={["user", "username"]}
              label="Username"
              required
            >
              <Input.Group compact>
                <Input
                  value={username1}
                  placeholder="Username"
                  maxLength={20}
                  onChange={(e) => {
                    setUsername(e.target.value.trim());
                  }}
                  onBlur={(e)=>{
                    validateUsername(e.target.value)
                  }}
                />
              </Input.Group>
            </Form.Item> */}

            {/* <Form.Item
              name={["user", "nickname"]}
              label="Nickname"
              required
            >
              <Input
                value={nickname}
                placeholder="Nickname"
                onChange={(e) => {
                  setNickname(e.target.value.trim());
                }}
              />
            </Form.Item> */}

            {isAdminCS && (
              <Form.Item
                name={["user", "shareholder"]}
                label="Shareholder"
                required
              >
                <Select
                value={shareholderSelection}
                optionFilterProp="children"
                ref={reportShareholderRef}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => {
                  setShareholderSelection(value);
                }}
                >
                  {shareholderOptions.map((option) => {
                    return (
                      <Option key={option.key} value={option.shareholder}>
                        {option.username}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            )}

            <Form.Item
                name={["user", "lineid"]}
                label="Line ID"
            >
              <Input.Group compact>
                <Input
                      value={lineId}
                      placeholder="Line Id"
                      maxLength={50}
                      onChange={(e) => {
                      setLineId(e.target.value);
                      }}
                  />
              </Input.Group>
                
            </Form.Item>

            {/* <Form.Item
              name={["user", "password"]}
              label="Password"
              required
            >
              <Input.Password
                value={password}
                placeholder="Password"
                onChange={(e) => {
                  setPassword(e.target.value.trim());
                }}
              />
            </Form.Item> */}

            <Form.Item
                // name={["user", "referralCode"]}
                label="Affiliate Code"
            >
              <Input
                  value={referralCode}
                  minLength={6}
                  maxLength={12}
                  placeholder="E.g. tkhxxxxx"
                  onChange={(e) => {
                      setReferralCode(e.target.value.toLowerCase());
                  }}
              />
              <div style={{color: "blue", fontSize: "10pt"}}><i>If this field is left empty, a random affiliate code will be generate for user.</i></div>
            </Form.Item>

            <Form.Item
              name={["user", "agentType"]}
              label="Agent Type"
              required
            >
              <Select 
                value={agentType} 
                onChange={(e) => {
                  setAgentType(e);
                }}
              >
                <Option value="Marketing">Marketing</Option>
                <Option value="CRM">CRM</Option>
              </Select>
            </Form.Item>

            <Form.Item style={{ textAlign: "right" }}
            >
              <Button
                htmlType="button"
                onClick={onCancel}
                style={{ marginRight: 10 }}
                loading={createButtonLoading}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                loading={createButtonLoading}
                onClick={() => {
                  handleAdd(0);
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
      </Modal>
      <Modal 
        title="Edit Agent Detail"
        visible={editVisible}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        <Form 
          layout="vertical"
          ref={modalEditForm}
          initialValues={{
            ag_nickname : editNickname,
            ag_mobilenumber : editMobileNumber,
            ag_pin : editPin,
            ag_agentType : editAgentType
          }}
          >
            {/* <Form.Item
              name="ag_mobileNumber"
              label="Mobile No"
            >
                <Input.Group compact>
                  <Select 
                    style={{width:"20%"}} 
                    value={editCountryCode} 
                    onChange={onChangeCountryCodeV2}
                  >
                    <Option value="856">+856</Option>
                    <Option value="66">+66</Option>
                    <Option value="60">+60</Option>
                  </Select>
                <Input 
                  style={{width:"80%"}} 
                  defaultValue="" 
                  placeholder="phone number" 
                  value={editMobileNumber}
                  onChange={(e) => {
                    setEditMobileNumber(e.target.value.replace(/\D|^0/g, ""));
                  }}
                  onBlur={(e)=>{
                    let requestData = {
                      action : 'edit',
                      mobileNumber: e.target.value.replace(/\D|^0/g, "")
                    }
                    validateUserPhone(requestData)
                  }}
                />
                </Input.Group>
            </Form.Item> */}

            <Form.Item
              name="ag_nickname"
              label="Nickname"
            >
              <Input.Group compact>
                <Input
                  value={editNickname}
                  placeholder="Nickname"
                  onChange={(e) => {
                      setEditNickname(e.target.value);
                  }}
                >
                </Input>
              </Input.Group>
            </Form.Item>

            <Form.Item
              name="ag_lineid"
              label="Line ID"
            >
              <Input.Group compact>
                <Input
                  value={editLineId}
                  placeholder="Line ID"
                  maxLength={50}
                  onChange={(e) => {
                    setEditLineId(e.target.value);
                  }}
                />
              </Input.Group>
            </Form.Item>
            
            {/* { compCode == 'TKH' ?
              <Form.Item
                name="ag_agentType"
                label="Agent Type"
              >
                <Input.Group compact>
                  <Select 
                    style={{width: "30%"}}
                    value={editAgentType} 
                    disabled={ editAgentType == 'Internal' ? true : false }
                    onChange={(e) => {
                      setEditAgentType(e);
                    }}
                  >
                    <Option value="Marketing">Marketing</Option>
                    <Option value="Crm">CRM</Option>
                  </Select>
                </Input.Group>
              </Form.Item>
            :
              <></>
            } */}

            {/* { (compCode != 'OGT' && compCode != 'TKC') ?
              <Form.Item
                  name="ag_pin"
                  label="Pin"
                >      
                  <Input.Group compact>
                    <Input
                        value={editPin}
                        placeholder="6 Digit Pin"
                        maxLength={40}
                        onChange={(e) => {
                          const newValue = e.target.value.toLowerCase().replace(/\s/g, ''); // Convert to lowercase and remove whitespace
                          // Regular expression to match only lowercase letters and numbers
                          const isValid = /^[a-z0-9]*$/.test(newValue);
                          if (isValid) {
                            setEditPin(newValue);
                          }
                        }}
                    />
                  </Input.Group>       
              </Form.Item>
            :
              <></>
            } */}

            <Form.Item
              name="ag_password"
              label="Password"
              >      
                <Input.Group compact>
                  <Input.Password
                    value={editPassword}
                    placeholder="Password"
                    onChange={(e) => {
                      setEditPassword(e.target.value);
                    }}
                  />
                </Input.Group>       
            </Form.Item>

            <Form.Item style={{ textAlign: "right" }}>
                <Button
                  htmlType="button"
                  onClick={onCancelEdit}
                  loading={editButtonLoading}
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  loading={editButtonLoading}
                  onClick={() => {
                    handleEdit();
                  }}
                >
                  Submit
                </Button>
            </Form.Item>
        </Form>
      </Modal>
      {isIPad13 || isLandscape
        ? landscape()
        : isMobile
        ? portrait()
        : landscape()}
    </div>
  );
}
